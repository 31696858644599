import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import BlogsPage from "./Layouts/Blogs/BlogsPage";
import BannerSection from "./Layouts/HomePage/BannerSection";
import NewsAnnouncements from "./Layouts/HomePage/NewsAnnouncements/NewsAnnouncements";
import BusinessIncubation from "./Layouts/HomePage/BusinessIncubation/BusinessIncubation";
import OurMemberships from "./Layouts/HomePage/OurMemberships/OurMemberships";
// import Initiatives from "./Layouts/HomePage/Initiatives/Initiatives";
import Events from "./Layouts/HomePage/Events/Events";
import AwardPage from "./Layouts/AwardsPage/AwardPage";
import ContactUsPage from "./Layouts/ContactUsPage/ContactUsPage";
import EventPage from "./Layouts/EventPage.js/EventPage";
import GeoinformaticsPage from "./Layouts/GeoinformaticsPage/GeoinformaticsPage";
import AboutUs from "./Layouts/AboutUs/AboutUs";
import BusinessIncubationBySectorPage from "./Layouts/BusinessIncubationBySectorPage/BusinessIncubationBySectorPage";
import MembershipPage from "./Layouts/MembershipPage/MembershipPage";
import SingleBlog from "./Layouts/Blogs/SingleBlog";
import Header from "./Components/Header";
import Domains from "./Layouts/HomePage/Domains/Domains";
import SpecialProjects from "./Layouts/HomePage/SpecialProjects/SpecialProjects";

import ScrollTop from "./Components/ScrollTop";
import DstNidhi from "./Layouts/DST-NIDHI/DstNidhi";
import StartupIndia from "./Layouts/BusinessIncubation/StartupIndia/StartupIndia";
import ProductsInnovation from "./Layouts/ProductsInnovation/ProductsInnovation";
import BusinessIncubationPage from "./Layouts/BusinessIncubationPage/BusinessIncubationPage";
import SkillTraining from "./Layouts/SkillTraining/SkillTraining";
import CSR from "./Layouts/CSR/CSR";
import CIIICenters from "./Layouts/BusinessIncubation/CIIICenters/CIIICenters";
import STPDonations from "./Layouts/STPDonations/STPDonations";
import GreenDonations from "./Layouts/GreenDonations/GreenDonations";
import AnnouncementPage from "./Layouts/AnnouncementPage/AnnoncementaPage";
import SingleEvent from "./Layouts/EventPage.js/SingleEvent";

import NidhiSSP from "./Layouts/BusinessIncubation/NidhiSSP/nidhiSSP";
import GrowthLabBi from "./Layouts/BusinessIncubation/growthLab/growthLab";
import BusinessIncubatees from "./Layouts/BusinessIncubation/incubatees/incubatees";
import NidhiPrayasProgram from "./Layouts/BusinessIncubation/NidhiPrayas/NidhiPrayas";
import GrowthLab from "./Layouts/BusinessIncubation/growthLab/growthLab";
import NidhiEIR from "./Layouts/BusinessIncubation/NidhiEIR/NidhiEIR";
import SingleGeoinformaticContent from "./Layouts/GeoinformaticsPage/SingleGeoinformaticContent";
import UpcomingEvents from "./Layouts/EventPage.js/UpcomingEvents";
import ScitechAirosan from "./Layouts/ProductsInnovation/ScitechAirosan";
import ScitechUvSan from "./Layouts/ProductsInnovation/ScitechUvSan";
import ScitechHivenion from "./Layouts/ProductsInnovation/ScitechHivenion";
import PlugIn from "./Layouts/ProductsInnovation/PlugIn";
import Traveller from "./Layouts/ProductsInnovation/Traveller";
import Barti from "./Layouts/SkillTraining/Barti";
import NetSkills from "./Layouts/SkillTraining/NetSkills";
import TechBasedProjects from "./Layouts/SkillTraining/TechBasedProjects";
import TrainingForDisabled from "./Layouts/SkillTraining/TrainingForDisabled";
import SpecialProjectsPage from "./Layouts/SpecialProjects/specialProjectsPage";
import BoardofTrustees from "./Layouts/BoardOfTrustees/BoardofTrustees";
import Environment from "./Layouts/Environment";
import AwardsBySTP from "./Layouts/AwardsPage/AwardsBySTP";
import AwardsByOther from "./Layouts/AwardsPage/AwardsByOther";
import OldBanner from "./Layouts/HomePage/BannerSection/oldBanner";
import IntegratedDev from "./Layouts/IntegratedDev/IntegratedDev";
import Panawadi from "./Layouts/IntegratedDev/Panawadi";
import Chandar from "./Layouts/IntegratedDev/Chandar";
import SchoolLibrary from "./Layouts/IntegratedDev/PanawadiSubpages/SchoolLibrary";
import WaterATM from "./Layouts/IntegratedDev/PanawadiSubpages/WaterATM";
import RiceDeHusking from "./Layouts/IntegratedDev/PanawadiSubpages/RiceDeHusking";
import PanawadiPlantation from "./Layouts/IntegratedDev/PanawadiSubpages/PanawadiPlantation";
import BhisewadiPlantation from "./Layouts/IntegratedDev/PanawadiSubpages/BhisewadiPlantation";
import SolarWaterSystem from "./Layouts/IntegratedDev/PanawadiSubpages/SolarWaterSystem";
import PanwadiPlantationII from "./Layouts/IntegratedDev/PanawadiSubpages/PanwadiPlantation-II";
import MedicinalPlantation from "./Layouts/IntegratedDev/PanawadiSubpages/MedicinalPlantation";
import PlantationForSeniors from "./Layouts/IntegratedDev/PanawadiSubpages/PlantationForSeniors";
import PlantationBabdevSite from "./Layouts/IntegratedDev/PanawadiSubpages/PlantationBabdevSite";
import EyeCareCamp from "./Layouts/IntegratedDev/PanawadiSubpages/EyeCareCamp";
import TwoPedalBoats from "./Layouts/IntegratedDev/PanawadiSubpages/TwoPedalBoats";
import PanwadiPlantationIII from "./Layouts/IntegratedDev/PanawadiSubpages/PanwadiPlantation-III";

function App() {
  return (
    <Router>
      <div className="App">
        <div id="back-to-top-anchor" style={{ position: 'absolute', top: 0 }} />
        <Header />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <BannerSection />
                {/* <OldBanner /> */}
                <NewsAnnouncements />
                <Domains />
                <BusinessIncubation />
                <SpecialProjects />
                <Events />
                <OurMemberships />
              </>
            }
          />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/board-of-trustees" element={<BoardofTrustees />} />

          <Route path="/business-incubation" element={<BusinessIncubationPage />} />
          <Route path="/skill-training" element={<SkillTraining />} />
          <Route path="/skill-training/barti" element={<Barti />} />
          <Route path="/skill-training/eu-dst-net-skills" element={<NetSkills />} />
          <Route path="/skill-training/technology-based-skill-training-projects" element={<TechBasedProjects />} />
          <Route path="/skill-training/skill-training-for-differently-abled-persons" element={<TrainingForDisabled />} />

          <Route path="/geoinformatics" element={<GeoinformaticsPage />} />
          <Route path="/geoinformatics/:data" element={<SingleGeoinformaticContent />} />
          <Route path="/csr" element={<CSR />} />
          <Route path="/environment" element={<Environment />} />
          <Route path="/integrated-village-development" element={<IntegratedDev />} />
          <Route path="/integrated-village-development/panawadi" element={<Panawadi />} />
          <Route path="/integrated-village-development/panawadi/school-library" element={<SchoolLibrary />} />
          <Route path="/integrated-village-development/panawadi/water-atm" element={<WaterATM />} />
          <Route path="/integrated-village-development/panawadi/rice-de-husking-machine" element={<RiceDeHusking />} />
          <Route path="/integrated-village-development/panawadi/panawadi-plantation" element={<PanawadiPlantation />} />
          <Route path="/integrated-village-development/panawadi/bhisewadi-plantation" element={<BhisewadiPlantation />} />
          <Route path="/integrated-village-development/panawadi/solar-water-pumping-system" element={<SolarWaterSystem />} />
          <Route path="/integrated-village-development/panawadi/panawadi-plantation-phase-II" element={<PanwadiPlantationII />} />
          <Route path="/integrated-village-development/panawadi/medicinal-plantation" element={<MedicinalPlantation />} />
          <Route path="/integrated-village-development/panawadi/plantation-program-for-senior-citizen" element={<PlantationForSeniors />} />
          <Route path="/integrated-village-development/panawadi/plantation-babdev-side" element={<PlantationBabdevSite />} />
          <Route path="/integrated-village-development/panawadi/eye-care-camp" element={<EyeCareCamp />} />
          <Route path="/integrated-village-development/panawadi/two-pedal-boats" element={<TwoPedalBoats />} />
          <Route path="/integrated-village-development/panawadi/plantation-phase-III" element={<PanwadiPlantationIII />} />

          <Route path="/integrated-village-development/chandar" element={<Chandar />} />

          <Route path="/dst-nidhi" element={<DstNidhi />} />
          <Route path="/startup-india-seed-fund-scheme" element={<StartupIndia />} />
          <Route path="/growth-lab" element={<GrowthLab />} />
          {/* <Route path="/products-innovation" element={<ProductsInnovation />} /> */}
          <Route path="/products-innovation/scitech-uv-san" element={<ScitechUvSan />} />
          <Route path="/products-innovation/scitech-airosan" element={<ScitechAirosan />} />
          <Route path="/products-innovation/scitech-airosan/plug-in" element={<PlugIn />} />
          <Route path="/products-innovation/scitech-airosan/traveller" element={<Traveller />} />
          <Route path="/products-innovation/scitech-hivenion" element={<ScitechHivenion />} />
          <Route path="/ciii-centers" element={<CIIICenters />} />
          <Route path="/awards" element={<AwardPage />} />
          <Route path="/awards-by-stp" element={<AwardsBySTP />} />
          <Route path="/awards-by-incubatees" element={<AwardsByOther />} />

          <Route path="/events" element={<EventPage />} />
          <Route path="/event/:eventslug" element={<SingleEvent />} />
          <Route path="/upcomingEvents" element={<UpcomingEvents />} />

          <Route path="/news-and-media" element={<BlogsPage />} />
          <Route path="/announcements" element={<AnnouncementPage />} />

          <Route path="/stp-donations" element={<STPDonations />} />
          <Route path="/green-donations" element={<GreenDonations />} />

          <Route path="/contact-us" element={<ContactUsPage />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/business-incubation/:sector" element={<BusinessIncubationBySectorPage />} />
          <Route path="/nidhi-sss" element={<NidhiSSP />} />
          <Route path="/nidhi-prayas" element={<NidhiPrayasProgram />} />
          <Route path="/nidhi-eir" element={<NidhiEIR />} />
          <Route path="/business-incubation/growth-lab" element={<GrowthLabBi />} />
          <Route path="/incubatees" element={<BusinessIncubatees />} />
          <Route path="/blogs/:slug" element={<SingleBlog />} />
          {/* <Route path="/associations/:slug" element={<AssociationDetail />} />
          <Route path="/news-and-media/" element={<NewsAndMediaPage />} />
          {/* <Route path="/business-incubator/:slug" element={<GrowthLabDetail />} /> */}

          <Route path="/special-projects" element={<SpecialProjectsPage />} />
          <Route path="/memberships" element={<MembershipPage />} />
          <Route path="/growth-lab/:sector" element={<BusinessIncubationBySectorPage />} />
          <Route path="/news-and-media/:slug" element={<SingleBlog />} />
        </Routes>
        <Footer />
        <ScrollTop />
      </div>
    </Router>
  );
}

export default App;
