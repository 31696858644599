import React from "react";
import { Box, Typography } from "@mui/material";
import '../../fonts/fonts.css'; // Ensure this contains your @font-face rule for custom fonts

const Banner = ({
    title,
    fontFamily = "sans-serif", // Default font family
    minHeight = { xs: "20vh", md: "18vh", lg: "45vh" },
    fontSize = { xs: "2rem", md: "4rem", lg: "7rem" },
    letterSpacing = "0px",
}) => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: minHeight,
                // backgroundImage: `url(${backgroundImage})`,
                backgroundColor: "#243078",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={fontSize}
                    fontWeight="600"
                    sx={{
                        fontFamily: fontFamily,
                        letterSpacing: letterSpacing,
                    }}
                >
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default Banner;
