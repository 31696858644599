import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'

const AboutUsSection = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4,
                backgroundColor: '#ffffff',
            }}>
                <Box sx={{  color: "#1C1F35" }}>
                    <Container>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                                <Typography
                                    variant="body1"
                                    fontSize={'1.1rem'}
                                    paragraph
                                    sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                                >
                                    Science and Technology Park Pune (Scitech Park) is India’s oldest and leading technology accelerator and business incubator established by the Ministry of Science and Technology, Government of India in 1986. Scitech Park is located on the campus of Savitribai Phule Pune University and is a not-for-profit trust and society. Scitech Park is also a Satellite Centre for Policy Research under the Ministry of Science and Technology, Government of India.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={`${process.env.REACT_APP_STP_URL}/aboutUsImg/SCITECHPARK.jpg`}
                                        alt="EIR"
                                        style={{
                                            width: '42%',
                                            maxWidth: '400px',
                                            borderRadius: '1%',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default AboutUsSection