import React from 'react'
import { Box, Container, Typography } from '@mui/material';


const WaterATM = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Launch of Scitech Water ATM at Panawadi Village, Taluka Purandar, Dist. Pune on 18th October, 2019
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/WaterATM/STP-Water-ATM_Panawadi_result-scaled.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6"  pb={'2rem'}>
                    Scitech Water ATM – Clean and Safe drinking water project was successfully  launched on 18th October, 2019 at Panawadi Gaonthan and Bhise Wadi of  Panawadi village, Tq. Purandar, Dist. Pune. The project was supported by Sungard  Availability Services (I) Pvt. Ltd. under their CSR activity. This project was  launched to ensure that all the households of the four hamlets of Panawadi village  including the main gaothan would get uninterrupted supply of safe clean drinking  water which is extremely important for a healthy life of the villagers and proper  growth of the children.
                </Typography>
            </Container>
        </Box>
    )
}

export default WaterATM;
