import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const AboutUsBanner = () => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: { xs: "20vh", md: '18vh', lg: '45vh' },
                backgroundColor: "#243078",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem" }}
                    fontWeight="600"
                >
                    About Us
                </Typography>
                {/* <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={10} md={2}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Team Member
                            </Typography>
                            <Typography fontSize={"2.2rem"} fontWeight="700" color="#1C2B78">
                                500+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Incubates
                            </Typography>
                            <Typography fontSize={"2.2rem"} fontWeight="700" color="#1C2B78">
                                2000+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Mentors
                            </Typography>
                            <Typography fontSize={"2.2rem"} fontWeight="700" color="#1C2B78">
                                50+
                            </Typography>
                        </Box>
                    </Grid>
                </Grid> */}
            </Box>
        </Box>
    );
};

export default AboutUsBanner;
