import React from "react";
import Banner from "../../Components/Banner";
import { Box, Container, Typography, Grid, Card, CardMedia, CardContent } from "@mui/material";

const BoardofTrustees = () => {
    const boardMembers = [
        {
            name: "Dilip Band IAS (Retd.)",
            position: "President",
            designation: "Ex Divisional Commissioner, Pune",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/dilipBan.png`,
        },
        {
            name: "Pratap Pawar",
            position: "Vice President",
            designation: "Chairman, Sakal Media Group",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/pratappawar.png`, 
        },
        {
            name: "Anant Talaulicar",
            position: "Member",
            designation: "Ex Chairman & MD Cummins India",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/ananttalaulicar.png`, 
        },
        {
            name: "Praveen Roy",
            position: "Member",
            designation: "Head, NSTEDB, DST, Govt. of India",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/praveenroy.png`, 
        },
        {
            name: "Vikram Salunkhe",
            position: "Member",
            designation: "MD, Accurate Gauging & Instruments",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/vikramsalunke.png`, 
        },
        {
            name: "Pradeep Lokhande",
            position: "Member",
            designation: "Founder Rural Relations",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/pradeeplokhande.png`, 
        },
        {
            name: "Sohanlal Jain",
            position: "Member",
            designation: "Advocate",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/sohanjain.png`, 
        },
        {
            name: "Dr. Narayan Hegde",
            position: "Member",
            designation: "Principal Advisor & Managing Trustee, BAIF",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/narayanahegde.png`, 
        },
        {
            name: "Prof. Rajendra Jagdale",
            position: "Member Secretary",
            designation: "Director General & CEO, Science and Technology Park",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/rajendrajagdale.png`, 
        },
        
    ];

    return (
        <>
            <Banner title={"Board of Trustees"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    {/* <Typography
                        variant="h4"
                        fontWeight="600"
                        gutterBottom
                        textAlign="center"
                        mb='2rem'
                        >
                        Board Members
                    </Typography> */}
                    <Grid container spacing={4} justifyContent="center">
                        {boardMembers.map((member, index) => (
                            <Grid item xs={12} sm={6} md={3.5} key={index}>
                                <Card
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: "10px",
                                        boxShadow: 3,
                                        backgroundColor: "#ffffff",
                                        overflow: "hidden",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        src={member.image}
                                        alt={member.name}
                                        sx={{
                                            width: "120px",
                                            height: "120px",
                                            borderRadius: "50%",
                                            margin: "16px auto 0",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold", color: "#1C1F35" }}
                                        >
                                            {member.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#1C1F35" }}
                                        >
                                            {member.position}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#666666" }}
                                        >
                                            {member.designation}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BoardofTrustees;
