import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import pattern from "../../Media/Pattern.png";
import CustomTabs from './CustomTabs';

export default function CompanyOverview() {

    return (
        <Box sx={{ backgroundColor: '#d7e3fc', px: { xs: 2, md: 16 }, py: 4 }}>
            {/* <Grid container spacing={12} alignItems="center"> */}
            {/* <Grid item xs={4} md={6}>
                    <Box sx={{ position: 'relative' }}>
                        <img
                            src={OverviewImage}
                            alt="OverviewImage"
                        // style={{ width: { xs: '30rem', md: '80rem' } }}
                        />
                    </Box>
                </Grid> */}

            <Grid item xs={12} md={6}>
                <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} py={{ xs: 1, md: 2 }}>
                    <img height="41px" src={pattern} alt="pattern" />
                    <Typography
                        variant="h6"
                        backgroundColor="#eaeff3"
                        fontSize={"1.6rem"}
                        fontWeight="bold"
                        px="0.5rem"
                    >
                        Who We Are
                    </Typography>
                </Grid>
                {/* <Typography variant="h4" sx={{ mt: 2, color: "1C1F35", fontWeight: 'bold' }}>
                    Our Company Overview
                </Typography> */}
                <Typography sx={{ mt: 1.5, color: '#666C89', textAlign: 'justify' }}>
                    Over more than three decades, Scitech Park has invested in more than 240 S&T based startups and supported more than 1000 companies from diverse industry sectors such as Energy, Mobility, Medical, Education, Manufacturing, Cleantech, Circularity, Biotech and Agriculture. To support such emerging technologies, Scitech Park regularly engages with its global network of innovation parks, government agencies, investors, associates, experts, partners and vendors.
                    <br />
                    <br />
                    Scitech Park is actively supported by various government agencies such as Department of Science and Technology (GoI), Department of Biotechnology (GoI), Startup India, Invest India and Maharashtra State Innovation Society. We are a founding member of the Indian Science Park’s and Business Incubators’ Association (ISBA) and a member of the Asian Association of Business Incubation (AABI) having members from 19 countries. Our close association with more than a dozen world leading science parks and innovation from across the globe helps add value and exposure to our member startups and associate companies and organisations.

                </Typography>

                <CustomTabs />

                {/* <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            backgroundColor: "#091242",
                            color: "white",
                            padding: "1rem 3.4rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: 0,
                            ":hover": {
                                backgroundColor: "#5A6C9D",
                            },
                        }}
                    >
                        Learn More
                    </Button> */}
            </Grid>
            {/* </Grid> */}
        </Box>
    );
}
