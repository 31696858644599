import React from "react";
import { Box, Typography } from "@mui/material";
import "../../../fonts/fonts.css";

const Banner = ({
    title,
    fontFamily = "sans-serif",
    minHeight = { xs: "20vh", md: "18vh", lg: "35vh" },
    fontSize = { xs: "2rem", md: "4rem", lg: "6rem" },
    letterSpacing = "0px",
}) => {
    console.log("Environment Variables:", process.env);
    console.log("STP URL:", process.env.REACT_APP_STP_URL);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: minHeight,
                backgroundColor: "#243078",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                    paddingX: { xs: 2, sm: 4, md: 6 },
                }}
            >
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: { xs: 2, sm: 3 },
                    }}
                >
                    <img
                        src={`${process.env.REACT_APP_STP_URL}/businessSubPages/CIII-web-Intro_result.jpg`}
                        alt="startup"
                        style={{
                            width: "100%",
                            maxWidth: "400px",
                            // borderRadius: "5%",
                        }}
                    />
                </Box>
                <Typography
                    variant="h1"
                    fontSize={fontSize}
                    fontWeight="600"
                    sx={{
                        fontFamily: fontFamily,
                        letterSpacing: letterSpacing,
                        width: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                    }}
                >
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default Banner;
