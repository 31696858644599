import React from 'react'
import Banner from '../../Components/Banner'
import { Box, Container, Grid, Typography } from '@mui/material';

const items = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_1.webp`,
        link: "/integrated-village-development/panawadi/school-library",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_2.webp`,
        link: "/integrated-village-development/panawadi/water-atm",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_3.webp`,
        link: "/integrated-village-development/panawadi/rice-de-husking-machine",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_4.webp`,
        link: "/integrated-village-development/panawadi/panawadi-plantation",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_5.webp`,
        link: "/integrated-village-development/panawadi/bhisewadi-plantation",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_6.webp`,
        link: "/integrated-village-development/panawadi/solar-water-pumping-system",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_7.webp`,
        link: "/integrated-village-development/panawadi/panawadi-plantation-phase-II",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_8.webp`,
        link: "/integrated-village-development/panawadi/medicinal-plantation",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi-9.png`,
        link: "/integrated-village-development/panawadi/plantation-program-for-senior-citizen",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_10.png`,
        link: "/integrated-village-development/panawadi/plantation-babdev-side",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_11.png`,
        link: "/integrated-village-development/panawadi/eye-care-camp",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_12.png`,
        link: "/integrated-village-development/panawadi/two-pedal-boats",
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/Panawadi_13.png`,
        link: "/integrated-village-development/panawadi/plantation-phase-III",
    },
];

const PanwadiData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: 'Inauguration of School Library at Panawadi Village, Taluka Purandar, Dist. Pune on 02nd October, 2019',
        description: 'Scitech Park has inaugurated School Library at Panawadi village to inculcate  holistic development of all the children in the village with the help of Rural  Relations on 2nd October, 2019. The school library at Raje Shivray Vidyalay, Ghera Panawadi was inaugurated by Shri. Dilip Band, President, Science and Technology Park, Pune.'
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/`,
        title: '',
        description: ''
    },
]

const Panawadi = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
            <Banner title={"Panawadi"} />
            <Box sx={{ py: 4, textAlign: 'justify' }}>
                <Container>
                    {/* <Typography variant="h4" fontWeight="600" gutterBottom>
            Lorem ipsum
        </Typography> */}
                    <Typography variant="h6" paragraph>
                        Science and Technology Park, Pune has adopted Panawadi village for its complete socio-economic and ecological development and to transform it into a model replicable village with highest United Nations Sustainable Development Goals.
                    </Typography>
                </Container>
            </Box>

            <Grid container spacing={4} pb={6}>
                {items.map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        md={10}
                        key={index}
                        onClick={() => window.open(item.link, "_blank")}
                        sx={{
                            margin: "auto",
                            cursor: "pointer",
                            textAlign: "center",
                            "& img": {
                                width: "95%",
                                transition: "transform 0.3s",
                            },
                            "& img:hover": {
                                transform: "scale(1.05)",
                            },
                        }}
                    >
                        <img src={item.image} alt={`Image ${index + 1}`} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Panawadi;
