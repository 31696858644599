import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
} from "@mui/material";
import Banner from "../../Components/Banner";
import axios from "axios";

export default function SpecialProjectsPage() {
    const [specialProjects, setSpecialProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchSpecialProjects = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/specialproject/getall"
                );
                const allData = response?.data?.result;
                console.log(allData);
                if (Array.isArray(allData)) {
                    const latestData = allData.reverse();
                    setSpecialProjects(latestData);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error(error);
                setError("Failed to fetch special projects. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchSpecialProjects();
    }, []);
    
    return (
        <>
            <Banner title={'Special Projects'} />
            <Box
                sx={{
                    backgroundColor: "#f9fafc",
                    py: 6,
                    px: 2,
                    color: "#1c2b78",
                }}
            >
            <Grid
                container
                spacing={3}
                justifyContent="center"
                p={{ xs: 1, md: "0rem 12rem" }}
            >                    
            {specialProjects.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.Project_id}>
                    <Card
                        sx={{
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            color: "#000",
                            height: "100%",
                            boxShadow: 3,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >                    
                        <CardMedia
                            component="img"
                            image={`${docUrl}${project.Pro_image}`}
                            alt={project.Title}
                            sx={{
                                width: "100%",
                                height: "50%",
                                objectFit: "contain",
                                borderRadius: "10px 10px 0 0",
                            }}
                        />
                        <CardContent
                            sx={{
                                textAlign: "left",
                                padding: "0.5rem 1rem",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#1c2b78",
                                }}
                            >
                                {project.Title}
                            </Typography>
                        </CardContent>
                        <CardContent
                            sx={{
                                flexGrow: 1,
                                padding: "0.5rem 1rem",
                                textAlign: "justify",
                            }}
                        >                     
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ lineHeight: 1.5 }}
                            >
                                {project.Description}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
                </Grid>
            </Box>
        </>
    );
}
