import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import AwardsCard from "./AwardsCard";
import AwardBanner from "./AwardBanner";

const AwardsBySTP = () => {
    const [awards, setAwards] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/awards/getall"
                );
                setAwards(response?.data?.result);
            } catch (error) {
                console.error("Error fetching awards:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAwards();
    }, []);

    const renderAwards = (receivedBy) => {
        return awards
            .filter((award) => award.Received_by === receivedBy)
            .map((award, index) => (
                <AwardsCard key={award?.Award_id} docUrl={docUrl} item={award} />
            ));
    };

    return (
        <>
            <AwardBanner />
            <Box
                sx={{
                    maxWidth: "100%",
                    paddingBottom: "50px",
                    backgroundColor: "#d7e3fc",
                }}
            >
                <Typography
                    variant="h1"
                    backgroundColor="#1c2b78"
                    color="white"
                    textAlign={"center"}
                    fontSize={{ xs: "2rem", md: "2.5rem", lg: "3.5rem" }}
                    fontWeight={"600"}
                    mt={"1.8rem"}
                    py="3.5rem"
                >
                    Awards received by STP
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : awards.length > 0 ? (
                    <Grid
                        container
                        mt="6rem"
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {renderAwards("Science and Technology Park")}
                    </Grid>
                ) : (
                <Grid
                    container
                    py='4rem'
                    style={{
                        display: "grid",
                        placeItems: "center",
                        // paddingTop: "50px",
                    }}
                >
                    <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt="No data found"
                    />
                    <Typography textAlign="center" mt={2}>
                        No data available.
                    </Typography>
                </Grid>
                )}
            </Box>
        </>
    );
};

export default AwardsBySTP;
