import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EventBanner = ({ count }) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: { xs: "60vh", md: '35vh', lg: '50vh' },
                backgroundImage: `url(https://localmedia.org/wp-content/uploads/2021/06/events-1.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem" }}
                    fontWeight="600"
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                >
                    Events
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={10} md={2.5}>
                        {/* <Box
                            sx={{
                                backgroundColor: "white",
                                p: 1.5,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Events count
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                {count}
                            </Typography>
                        </Box> */}
                        <Grid item mt="2rem">
                            <Button
                                variant="outlined"
                                onClick={()=> navigate('/upcomingEvents')}
                                size="large"
                                sx={{
                                    backgroundColor: 'white',
                                    color: "#008bf9",
                                    fontWeight: '600'
                                }}
                            >
                                Our upcoming events
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default EventBanner;
