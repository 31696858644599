import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
} from "@mui/material";

const Header = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString("en-US", { month: "short" });
        const year = date.getFullYear();
        const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
        const time = date.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
        });
        return `${day} ${month} ${year} | ${dayOfWeek} | ${time}`;
    };

    return (
        <Box
            sx={{
                bgcolor: "#152265",
                color: "#b3bbca",
                padding: "8px",
                display: { xs: "grid", md: "flex" },
                justifyContent: "space-around",
                gap: { xs: "0.2rem", md: "24rem", lg: "25rem" },
                alignItems: "center",
                position: "sticky",
                top: 0,
                zIndex: 1101,
            }}
        >
            <Typography fontWeight={"bold"} variant="body2">
                Science and Technology Park, Pune
            </Typography>
            <Typography variant="body2">
                {formatDate(currentTime)}
            </Typography>
        </Box>
    );
};

export default Header;