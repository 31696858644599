import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import EUDST from '../../Media/euDst.png'

const NetSkills = () => {
    return (
        <>
            <Banner title={"EU DST NET Skills"} fontSize={{ xs: '2rem', md: '4rem' }} />
            <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Typography variant="h4" color='#152265' fontWeight="600" align="center" mb='2rem' gutterBottom>
                        Introduction
                    </Typography>
                    <Typography variant="body1" paragraph textAlign={'center'}>
                        It was funded by European Union, and Department of Science and Technology, Government of India. Training was imparted in renewable energy sector namely:
                    </Typography>
                </Container>
            </Box>

            <Container sx={{ py: { md: 4 } }}>
                {/* <Typography color="#152265" variant="h4" fontWeight="600" align="center" gutterBottom>
                        Objectives
                    </Typography> */}
                <List sx={{ color: "#", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
                    <ListItem sx={{ justifyContent: 'center', }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'center' }}
                            primary="1. Solar and Wind Energy Based Appliances and their Maintenance for Rural
Application." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'center' }}
                            primary="2. Biomass based Cooking Fuel and Energy Efficient Cook-stoves – Manufacturing and
Maintenance." />
                    </ListItem>
                </List>
            </Container>

            {/* <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                }}>
                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        paddingRight: { xs: 3, md: 5 },
                    }}>
                        <Typography
                            variant="body1"
                            fontSize={'1.1rem'}
                            paragraph
                            sx={{
                                color: '',
                                lineHeight: 1.6,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            508 persons have been trained involving school drop outs, Youth and women. A State-of-the-Art Nodal Training Center had been established at Sangamner. This training programme had helped Women Self Help Groups to such an extent that they got their first order for Solar LED Lamps from Forest Department, Govt. of Maharashtra worth about Rs. 11 Lakhs to be used by their beat officers for rounds.
                        </Typography>
                    </Box>
                    <Box sx={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            src={EUDST}
                            alt='EIR'
                            style={{
                                width: '60%',
                                maxWidth: '400px',
                                // borderRadius: '5%'
                            }} />
                    </Box>

                </Container>
            </Box> */}

            <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <Typography
                                variant="body1"
                                fontSize={'1.1rem'}
                                paragraph
                                sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                            >
                                508 persons have been trained involving school drop outs, Youth and women. A State-of-the-Art Nodal Training Center had been established at Sangamner. This training programme had helped Women Self Help Groups to such an extent that they got their first order for Solar LED Lamps from Forest Department, Govt. of Maharashtra worth about Rs. 11 Lakhs to be used by their beat officers for rounds.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={EUDST}
                                    alt="EIR"
                                    style={{
                                        width: '100%',
                                        maxWidth: '400px',
                                        borderRadius: '5%',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default NetSkills;