import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const EyeCareCampData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-2..webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-6.webp`
    },
]

const EyeCareCamp = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        New Vision for Panawadi: Scitech Park, Pune organized an Eye-Care Camp for villagers of Panawadi on 24th February, 2022 at Panawadi
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/EyeCareCamp/eccp-cover.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    The villagers of Panawadi were given a “New Vision” at an Eye-Care organized by the Science and Technology Park, Pune with support from Rotary Club of Centre Pune. More than 175 villagers availed of this NEW VISION Eye Camp held on 24th February 2022, that included students as young as 7 years of age and the oldest was 85-year-old senior resident of the village.
                    <br /> <br />
                    Senior residents of the village were also given 72 reading glasses, free of cost as a goodwill gesture by the Community Eye Care Foundation of Dr. Gogate’s Eye Clinic. Young students were also tested for color blindness, and thankfully, none tested positive to Color Blindness in the village.
                    <br /> <br />
                    The village of Panawadi, in the Purandar Tehsil, is approximately 40kms from Pune City and has a resident population of around 600 as per the last census. The NEW VISION Eye Care camp was organized in the Zilla Parishad Primary School, Panawadi and was supported by school teachers and Aanganwadis in the village. It was enthusiastically supported by Shri. Haribhau Lole, Social worker and Ex-Sarpanch, Panawadi and Shri. Abasaheb Lole, Sarpanch, Panawadi village.
                    <br /> <br />
                    This is first of the many health camps planned to be organized at Panawadi Village. Scitech Park, Pune has adopted this village with the intention of making it self-sustaining in every aspect of life by providing good health, quality education and improve the standard of life by making changes at grass root level. Panawadi is also developing as a tourist destination with aquatic activities, outdoor exercises and expected to evolve as a staycation tourist destination for residents of Pune and nearby cities.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {EyeCareCampData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default EyeCareCamp;
