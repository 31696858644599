import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, Typography } from '@mui/material';
import BartiBanner from './BartiBanner';

const Barti = () => {
    return (
        <>
            <Banner
                title={'Dr. Babasaheb Ambedkar Research and Training Institute (BARTI), Pune'}
                fontSize={{ xs: '1.5rem', md: '4rem' }}
            />
            <BartiBanner />
            <Box
                sx={{
                    py: { xs: 4, md: 6 },
                    backgroundColor: "#d7e3fc",
                    color: "#1C1F35",
                }}
            >
                <Container>
                    <Typography
                        variant="h5"
                        fontWeight="600"
                        gutterBottom
                        textAlign={'justify'}
                        sx={{
                            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                        }}
                    >
                        Skill training and entrepreneurship development programme for 240 SC candidates in Pune and
                        Satara awarded by Dr. Babasaheb Ambedkar Research and Training Institute (BARTI), Pune
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        textAlign={'justify'}
                        sx={{
                            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                            my: { xs: '1.5rem', md: '2rem' },
                        }}
                    >
                        Under this programme SC candidates are being trained in domains like CCTV Installation Technician, LED Repair Technician, Mobile Phone Hardware Repair Technician and Solar Panel Installation Technician. The training programmes are being conducted at Yashavantrao Chavan Centre for Invention, Innovation and Incubation (YC-CIII), Satara and LBP Centre for Invention, Innovation and Incubation at Rayat Shikshan Sanstha’s S. M. Joshi College Campus, Hadapsar, Pune set up by Scitech Park, Pune in collaboration with Rayat Shikshan Sanstha, Satara.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: { xs: 2, md: 4 },
                        }}
                    >
                        <img
                            src={`${process.env.REACT_APP_STP_URL}/barti/BARTI.png`}
                            alt='EIR'
                            style={{
                                width: '100%',
                                maxWidth: '800px',
                                borderRadius: '5px',
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Barti;
