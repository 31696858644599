import React from "react";
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { staticData } from "./staticData";
import Banner from "./Banner";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SingleGeoinformaticContent = () => {
  const { data } = useParams();
  const navigate = useNavigate();

  // Find the news item with the matching slug
  const item = staticData.find((item) => item.slug === data);
  if (!item) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Typography variant="h6">Data not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <Banner title={item.Title} letterSpacing="5px" />
      <IconButton
        onClick={() => navigate("/geoinformatics")}
        sx={{
          color: "black",
          position: "relative",
          ml: { xs: '10px', md: "90px" },
          mb: "-50px",
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        pt="3rem"
        m="auto"
        sx={{
          maxWidth: { xs: "90%", md: "80%" },
          mx: "auto",
          minHeight: { xs: "30vh", md: "60vh" },
          overflow: "hidden",
        }}
      >
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div className="carousel-indicators">
            {item.Images.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : undefined}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {item.Images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  aspectRatio: "20/10",
                  minHeight: "30vh",
                }}
              >
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "50%",
              width: "3rem",
              height: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: "1rem",
              zIndex: 1,
            }}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)",
              }}
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "50%",
              width: "3rem",
              height: "3rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "1rem",
              zIndex: 1,
            }}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
              style={{
                textShadow: "-2px 2px 5px rgba(0, 0, 0, 0.8)",
              }}
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </Box>
      {/* Content Section */}
      <Box sx={{ bgcolor: "#f4f6fc", padding: { xs: '1.5rem', md: '1rem 6rem' }, marginTop: '3rem' }}>
        <Typography
          variant="body1"
          fontSize="1.1rem"
          gutterBottom
          my="0.1rem"
          color="#364356"
          textAlign="justify"
        >
          {item.Description}
        </Typography>
      </Box>

      <Box
        sx={{
          py: 4,
          px: { xs: 2, md: 8 },
          backgroundColor: "#ccdbfd",
          color: "#1C1F35",
        }}
      >
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ color: "#0099DE", fontWeight: "bold", textAlign: "center" }}
            >
              Clientele
            </Typography>
          </Grid>
          {(Array.isArray(item.Clientele) ? item.Clientele : item.Clientele.split(",")).map((client, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "#f4f6fc",
                  borderRadius: "8px",
                  padding: "1rem",
                  textAlign: "center",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "#364356", fontWeight: "600" }}
                >
                  {typeof client === "string" ? client.trim() : client}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <IconButton
          onClick={() => navigate("/geoinformatics")}
          sx={{
            color: "black",
            // position: "relative",
            // ml: "30px",
            // mb: "-50px",
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default SingleGeoinformaticContent;