import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import SkillTrainingBanner from './skillTrainingBanner';

const TrainingForDisabled = () => {
    return (
        <>
            <SkillTrainingBanner title={"Skill Training for Differently Abled Persons"} fontSize={{ xs: '1.8rem', md: '4rem' }} />
            <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Typography variant="h4" color='#152265' fontWeight="600" align="center" mb={{ xs: '1rem', md: '2rem' }} gutterBottom>
                        Introduction
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Skill Training and Entrepreneurship Development Programmes have been the forte of Science and Technology Park, Pune with numerous training programmes conducted successfully over the past 20+ years.
                        <br />
                        <br />
                        Empanelled Training Partner of Ministry of Social Justice and Empowerment, Govt. of India and  Skill Council for Persons with Disability as Training Provider (TP) under the provisions of NSDC (National Skill Development Corporation), DEPwD (Department of Empowerment for Persons with Disabilities) and SCPwD (Skill Council of Persons with Disability) schemes or any other scheme of Government of India or State mission being implemented by NSDC/DEPwD through SCPwD or directly by SCPwD for persons with disability;
                        <br />
                        <br />
                        Since 2015-16 Scitech Park, Pune has conducted 100+ skill and entrepreneurship development training programmes for differently abled persons under National Action Plan for Skill Development of Persons with Disabilities (Under Scheme for Implementation of Persons with Disabilities Act (SIPDA) or under CSR initiative of corporates awarded by Ministry of Social Justice and Empowerment, Govt. of India and National Divyangjan Finance and Development Corporation (NDFDC), Govt. of India.
                        <br />
                        <br />
                        Scitech Park, Pune has successfully trained around 2000 PwDs with an average employment generation of 73% per year. These training programmes have helped and empowered the persons with disabilities with technical skill sets enabling them to lead a life of dignity through financial independence and not succumb to exploitative elements of the society.
                    </Typography>
                </Container>
            </Box >

            <Container sx={{ py: { md: 4 } }}>
                {/* <Typography color="#152265" variant="h4" fontWeight="600" align="center" gutterBottom>
                        Objectives
                    </Typography> */}
                <List sx={{ color: "#364356", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
                    <ListItem sx={{ justifyContent: 'center', }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="1. Training of 325 persons with disabilities in TV repair technician, Mobile Phone Hardware Repair Technician, Assistant Hair Stylist, Beauty Therapist, Sewing Machine Operator and Dairy Farmer Entrepreneur, awarded by Ministry of Social Justice & empowerment, Govt. of India and conducted at Thane, Ahmednagar, Palghar, Dhule and Raigad districts of Maharashtra." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="2. Training of 300 PwDs in domestic biometric data operator, sewing machine operator, CRM domestic non-voice, hand embroider and documentation assistant awarded by awarded by Ministry of Social Justice & empowerment, Govt. of India, under National Action Plan under SIPDA and conducted at Hingoli, Pune, Dhule, Nandurbar and Nasik districts of Maharashtra." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="3. Skill training and Entrepreneurship Development Programme (EDP) for 300 persons with Disabilities ,150 under CSR of Mazgaon Dock Ltd. and 150 under EDP of NDFDC, Ministry of Social Justice and Empowerment, Govt. of India in documentation Assistant (90) Hand set Repair Engineer (110) and Domestic Data Entry Operator (100) in Maharashtra.
" />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="4. Skill training and EDP for 220 PwDs under the CSR of Engineers India Ltd (EIL) awarded by NDFDC. Place of training Pune (60 PwDs), Solapur (60 PwDs), Thane (60 PwDs), & Jalgaon (40 PwDs). Trades - Handset repair (Level - II), Domestic data Entry Operator, Documentation Assistant (Logistic)." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="5. 3 training programmes on Hardware & Networking, Desktop Publishing, Printer Service & Maintenance for 90 differently abled candidates under ESDP of NHFDC, Ministry of Social Justice and Empowerment, Govt. of India." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="6. Training of 180 PwDs awarded by Ministry of Social Justice & Empowerment, Govt. of India under National Action Plan under SIPDA in domestic data entry operator, self-employed tailor, sewing machine operator and TV repair technician from districts like Hingoli, Ahmednagar, Thane, Satara and Jalna districts of Maharashtra." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="7. Skill and Entrepreneurship Development Programme for 197 Persons with Disabilities under the CSR of Shipping Corporation of India Ltd. (SCI) awarded by NDFDC in the aspirational district of Osmanabad, Maharashtra for handset repair engineer, sewing machine operator and domestic data entry operator." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="8. Skill and Entrepreneurship Development Programme for 100 Persons with Disabilities under the CSR of Indian Railway Finance Corporation Ltd. (IRFC) awarded by NDFDC in the aspirational district of Osmanabad and Jalgaon of Maharashtra for domestic data entry operator, self-employed tailor and beauty therapist." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="9. Skill and Entrepreneurship Development Programme of additional 200 Persons with Disabilities under the CSR of Indian Railway Finance Corporation Ltd. (IRFC) awarded by NDFDC in the aspirational district of Osmanabad and Jalgaon of Maharashtra for domestic data entry operator, self-employed tailor and beauty therapist." />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            primary="10. Skill and Entrepreneurship Development Programme for 175 PwDs under the CSR initiative of Indian Railway Finance Corporation (IRFC) awarded by NDFDC in the aspirational districts of Osmanabad, Jalgaon and Ahmednagar for Domestic Data Entry Operator and LED Repair Technician." />
                    </ListItem>
                </List>
            </Container>
        </>
    )
}

export default TrainingForDisabled;