import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import pattern from "../../Media/Pattern.png";

export default function IncubationServices() {
    // const services = [
    //     {
    //         // icon: AboutIcon1, 
    //         title: "360° Business incubation support"
    //     },
    //     {
    //         //  icon: AboutIcon2,
    //         title: "Technology Acceleration and Commercialization"
    //     },
    //     {
    //         // icon: AboutIcon3, 
    //         title: "Science and Technology based Advisory Services to Public and Private Organizations"
    //     },
    //     {
    //         // icon: AboutIcon4, 
    //         title: "Policy Research"
    //     },
    //     {
    //         // icon: AboutIcon5,
    //         title: "Innovation Ecosystem Development"
    //     },
    //     {
    //         // icon: AboutIcon6, 
    //         title: "Govt. Innovation Support Programs"
    //     },
    //     {
    //         title: "Skill Training"
    //     },
    //     {
    //         title: "CSR"
    //     },
    //     {
    //         title: "Geoinformatics"
    //     },
    //     {
    //         title: "Consultancy Services"
    //     }
    // ];

    return (
        <Box sx={{ backgroundColor: "#f4f4f4", px: { xs: 2, md: 10 }, py: 8 }}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <img height="41px" src={pattern} alt="pattern" />
                <Typography
                    variant="h6"
                    backgroundColor="#eaeff3"
                    fontSize={"1.6rem"}
                    fontWeight="bold"
                    px="0.5rem"
                >
                    What We Do
                </Typography>
            </Grid>

            {[
                {
                    text: `Scitech Park's sustainability services include ESG reporting, materiality assessment, net-zero emissions and decarbonisation planning, energy transition planning, carbon accounting and sequestration monitoring, built environment decarbonisation planning, circularity planning for waste streams, and related management training and workshops.
Scitech Park has vast experience in conducting environmental impact assessment and monitoring, environmental conservation planning and implementing high impact socio-economic and environmental projects. Some of these include Mangrove mapping and conservation planning for Mumbai region, Reforestation project for Hilton Shilimb, Vision 2030 plan for Thane District, Slum free plan of action for Bhavnagar, Clean and Green MIDC Policy and more than 50 small and large EIA projects throughout India.
`,
                    image: `${process.env.REACT_APP_STP_URL}/aboutUsImg/Scitech+Sustainability+Club.png`,
                },
                {
                    text: `The Park has delivered cutting-edge GIS-based solutions and services across India for asset mapping and monitoring, data mapping, infrastructure mapping, digital twinning, land use change monitoring, town planning and boundary mapping and digitisation, environmental impact monitoring and time series analysis for documenting historic and present conditions. Our open-source web-GIS Open PRP platform incorporates advanced data analysis and representation tools, dashboards and geospatial information and can be customised to be used as a Unified Decision-Making System. AI/ML and Blockchain integration is being explored for wider, faster, more reliable and transparent applications.`,
                    image: `${process.env.REACT_APP_STP_URL}/aboutUsImg/GIS.png`,
                },
                {
                    text: `The SciTech Innovation Club was established to guide entrepreneurs through expert sessions, webinars, and interactive discussions on key business topics. It aims to nurture businesses by providing insights from industry leaders and fostering a collaborative environment for entrepreneurs. The club serves as a platform to build a strong entrepreneurial network where members can share ideas, discuss intellectual business-related topics, and gain valuable knowledge. Additionally, it offers updates on funding opportunities and resources to support business growth. With a focus on innovation and collaboration, the SciTech Innovation Club is dedicated to empowering entrepreneurs to succeed in their ventures.`,
                    image: `${process.env.REACT_APP_STP_URL}/aboutUsImg/Innovation+Club.jpg`,
                },
                {
                    text: `Scitech Park's flagship program – ‘Scitech CIII’ (Centre for Invention, Innovation and Incubation) is designed to enable us to scale up our efforts to support budding innovators and entrepreneurs and to handhold academic institutions to develop their ecosystem for innovation and entrepreneurship development. Through the Scitech CIII program we have established centres in Maharashtra state; at Navi Mumbai, Pune, Satara and Kolhapur. The Scitech CIII model is based on a strong industry partnership that brings the industry to the campus as an advisor, mentor and skills trainer and also facilitates procurement of the correct equipment and software at discounted rates and in line with future industry demands.`,
                    image: `${process.env.REACT_APP_STP_URL}/aboutUsImg/CIII.jpeg`,
                },
                {
                    text: `Scitech Park is hugely active in the regional and rural development sector with the support of its philanthropic partners on aspects such as health and hygiene, energy and electrification, watershed planning, environment and plantation, education, agriculture, tourism etc. Through various donor driven initiatives, we have delivered several impactful projects covering various aspects such as environmental conservation, livelihood development, skills training, entrepreneurship development, health and education to name a few. Scitech Park is eligible to receive donations as well as CSR funds. Scitech Park is registered under 80G, 12A and CSR Form 1 and also under FCRA.`,
                    image: `${process.env.REACT_APP_STP_URL}/aboutUsImg/CSR.jpg`,
                }
            ].map((item, index) => (
                <Box key={index} sx={{ py: 4, color: "#1C1F35" }}>
                    <Container>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}>
                                <Typography
                                    variant="body1"
                                    fontSize={'1.1rem'}
                                    paragraph
                                    sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                                >
                                    {item.text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={item.image}
                                        alt="EIR"
                                        style={{
                                            width: index === 1 ? "85%" : "60%",
                                            maxWidth: index === 1 ? "500px" : "400px",
                                            borderRadius: '5%',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ))}

            {/* <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="center"
                mt={2}
                mb={6}
            >
                Our Incubation Services and entrepreneurship development
            </Typography>
            <Typography variant='h6' sx={{ color: "#666C89", mt: 1.2, mx: { sm: "1rem", md: '12rem' }, textAlign: 'center' }}>
                Business incubation and entrepreneurship development foster innovation and growth by providing startups with mentorship, funding, resources, and networking opportunities, helping turn innovative ideas into successful enterprises.
            </Typography> */}

            {/* <Grid
                container
                mt="2rem"
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "4rem", lg: "2rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "4rem" },
                }}
            >   
                {services.map((service, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={index}
                        sx={{
                            borderRight: '4px solid #e0e0e0',
                            borderBottom: '4px solid #e0e0e0',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)', 
                            padding: 2, 
                            borderRadius: '4px',
                            backgroundColor: '#d7e3fc', 
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.02)', 
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', 
                            },
                        }}
                    >
                        <img src={service?.icon} alt={service?.title} /> 
                        <Typography variant="h6" >
                            {service.title}
                        </Typography>
                        <Typography sx={{ color: "#666C89", mt: 1.5 }}>
                            Following the quality of our service thus having gained trust of
                            our many clients.
                        </Typography> 
                    </Grid>
                ))}
            </Grid> */}


            {/* <Box textAlign="center" mt={6}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#091242",
                        color: "white",
                        padding: "1rem 3rem",
                        fontWeight: 550,
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#5A6C9D",
                        },
                    }}
                >
                    More Works
                </Button>
            </Box> */}
        </Box>
    );
}
