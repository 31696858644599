import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const PlantationBabdevSiteData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_6.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_7.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_8.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_9..webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_10.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_11.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_12.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_13.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_14.webp`
    },
]

const PlantationBabdevSite = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Plantation Programme Phase II carried out with the support of FICCI FLO, Pune Chapter, on Wednesday, 21st December, 2021 at Panawadi
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationBabdevSite/PPFF_cover.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Aligned with the United Nations 17 Sustainable Development Goals and as a part of Science and Technology Park’s ambitious mission of planting 50,000 fruit trees within next three years at Panawadi, the Phase II plantation programme was carried out with the support of FICCI FLO, Pune Chapter, on Wednesday, 22nd December, 2021 at Gat No. 1209 farmland near Babdev site at Panawadi. A total of 50 saplings of unique Singapore Wada and Ramchandra variety of Jack Fruit have been planted during the programme. These rare, grafted and red fleshed jackfruit varieties which will start fruiting within 3-5 years of its plantation.
                    <br /> <br />
                    The dignitaries present for the plantation programme were Ms. Usha Poonawalla, Chairperson, FICCI FLO, Pune Chapter; Ms. Mariam Daruwala, Member, FICCI FLO, Pune Chapter; Dr. Gayatri Tiwari, Member, FICCI FLO, Pune Chapter; Ms. Sabiya Poonawala, Member, FICCI FLO, Pune Chapter, Dr. Rajendra Jagdale, DG & CEO, Science and Technology Park, Pune; Mr. Vigyan Arya, Science and Technology Park, Pune, Mrs. Nalini Lole, President, Panchayat Samiti, Panawadi; Mr. Abasaheb Lole, Sarpanch, Panawadi; Mr. Vaibhav Nimgire, Science and Technology Park, Pune, Mr. Sanmati Shetgar, Science and Technology Park, Pune, Mr. Haribhau Lole; Mr. Sandip Bhise; Mrs. Sushma Bhise and members of Adarsh Mahila Bachat Gat and Waghjai Mata Shetmal Utpadak Company, Panawadi.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {PlantationBabdevSiteData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default PlantationBabdevSite;
