import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import csrVerticals from "../../Media/csrverticals.png";
import unDgs from "../../Media/undgs.png";
import CsrBanner from './csrBanner';

const sectionStyle = {
    py: 4,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 4,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const CSR = () => {
    return (
        <>
            <CsrBanner />
            <Box sx={{ ...sectionStyle }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        CORPORATE SOCIAL RESPONSIBILITY
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'justify' }}
                    >
                        Scitech Park, Pune has been actively and aggressively engaged in various CSR projects for over 12+ years, focusing on innovative science and technology interventions and in line with United Nations Sustainable Development Goals (SDG). Scitech CSR activities are unique and are a complete solution to Conceive, Conceptualize and Execute the most unique CSR activities and projects. Scitech Park’s innovation driven interventions focusses on health, hygiene, sanitation, safe drinking water, zero-hunger, no-poverty, renewable energy solutions, quality education, gender equality, skill training, environment and holistic community development. These initiatives have earned numerous accolades and have improved the livelihoods of marginalized communities. The activities in these domains are structured to be innovation-driven, inclusive, and sustainable.
                        <br />
                        <br />
                        Scitech Park is on a mission to create technology-based ventures that address fundamental unresolved societal issues. The Indian government has introduced several provisions under the Companies Act, 2013, allowing corporations to contribute their Corporate Social Responsibility (CSR) funds to approved Technology Business Incubators (TBIs). Supported by the Department of Science and Technology, Government of India, Scitech Park, Pune is dedicated to collaborating with corporates to foster holistic community development, ensuring a better and more fulfilling life for all.
                    </Typography>
                </Container>
            </Box>

            <Box sx={{ ...sectionStyle2 }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        Scitech CSR Verticals
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <img
                            src={csrVerticals}
                            alt="CSR Verticals"
                            style={{
                                width: '100%',
                                maxWidth: '700px',
                                height: 'auto',
                                borderRadius: '5%',
                            }}
                        />
                    </Box>
                </Container>
            </Box>

            <Box sx={{ ...sectionStyle }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        Verticals Aligned to UN SDGs
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <img
                            src={unDgs}
                            alt="CSR Verticals"
                            style={{
                                width: '100%',
                                maxWidth: '800px',
                                height: 'auto',
                                borderRadius: '5%',
                            }}
                        />
                    </Box>
                </Container>
            </Box>

            <Box sx={{ ...sectionStyle2 }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        Clean Energy Initiatives
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'justify' }}
                    >
                        <strong>Scitech Surya Solar LED Lamps:</strong> Distributed 5000 lamps to tribal schoolchildren in Nanded and Palghar districts of Maharashtra and Dhamtari district of Chhattisgarh.
                        <br />
                        <br />
                        <strong>Scitech Surya Solar LED Home Lights:</strong> Distributed to 980+ tribal families from 13 remote villages within Bhimashankar Wildlife Sanctuary and 8 extremely remote villages of Velhe Taluka in Maharashtra.
                        <br />
                        <br />
                        <strong>Office Electrification:</strong> Solar electrification of offices of the Forest Department, Government of Maharashtra at Bhimashankar Wildlife Sanctuary.
                        <br />
                        <br />
                        <strong>Solar Water Pump:</strong> 1 HP, 2 HP, and 25 HP pumps installed at the range office of the Forest Department, Government of Maharashtra at Bhimashankar Wildlife Sanctuary, Panawadi, and Chander villages in Maharashtra.
                        <br />
                        <br />
                        <strong>E-Mobility Solutions:</strong> Retrofitted e-Gypsy for nature safaris.
                        <br />
                        <br />
                        <strong>Personal E-Mobility Solutions:</strong> Electric bikes, cycles, and wheelchairs.
                    </Typography>
                </Container>
            </Box>

            <Box sx={{ ...sectionStyle }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        Health & Hygiene
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'justify' }}
                    >
                        <strong>Patented Membrane Technology-Based Mobile Water Purification Units:</strong> Scitech Jaldoot, Scitech Water ATM, and centralized Scitech Water Purification Units provided for remote villages, temple trusts, hospitals, hostels, and wildlife sanctuaries in Maharashtra and Karnataka.
                        <br />
                        <br />
                        <strong>DNA-Based Diagnostics:</strong> Early detection of cervical cancer and cardiovascular diseases through screening programs for over 4000 women in Pune, Kolhapur, Raigad districts of Maharashtra, and Dhamtari, Rajnandgaon, and Bhilai districts of Chhattisgarh.
                        <br />
                        <br />
                        <strong>Eradication of Malnutrition:</strong> Addressing malnutrition in rural children through nutritionally protein-rich soymilk from Scitech Soymate in Jalgaon and Palghar districts of Maharashtra.
                        <br />
                        <br />
                        <strong>Digital Neonatal Healthcare:</strong> Transforming neonatal healthcare through innovations like the Smart Baby Warmer Kit (Intelli-Warm) and cloud-based solutions (Intelli-NICU).
                        <br />
                        <br />
                        <strong>Eco-Friendly Bio-Toilets:</strong> Installation of bio-toilets with odor-free biodigesters at Bhimashankar Wildlife Sanctuary, Maharashtra.
                        <br />
                        <br />
                        <strong>Mitigation of Indoor Air Pollution:</strong> Introduction of smokeless chulhas and bio-pellets in villages across Maharashtra to reduce indoor pollution.
                        <br />
                        <br />
                        <strong>Remote Healthcare Facilities:</strong> Remote and affordable healthcare solutions, including virtual diagnostic units, LIVE OPD, and OT WebLive technologies.
                    </Typography>
                </Container>
            </Box>

            <Box sx={{ ...sectionStyle2 }}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                    >
                        Digital Education
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'justify' }}
                    >
                        <strong>Interactive Virtual Classrooms:</strong> Scitech Eduprojector provides built-in, interactive course material for school children from grades 1 to 10, along with digital libraries and virtual classrooms.
                        <br />
                        <br />
                        <strong>Faculty Training:</strong> Skill-based live training and handholding programs for women and schoolchildren.
                        <br />
                        <br />
                        <strong>Science Labs & Libraries:</strong> Expertise in setting up science labs and providing hands-on science kits to rural schools in Maharashtra, as well as establishing rural libraries.
                        <br />
                        <br />
                        <strong>Robotics in Schools:</strong> Integrated robotics programs for school children based on the STEM concept.
                        <br />
                        <br />
                        <strong>Inclusive Learning Management:</strong> Platforms designed for children with special needs, alongside capacity-building programs for special educators.
                    </Typography>
                </Container>
            </Box>
        </>
    );
};

export default CSR;