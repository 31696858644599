import React from 'react'
import Banner from '../../Components/Banner'
import { Box, Container, Grid, Typography } from '@mui/material'
import SkillCard from './SkillCard'

const staticData = [
    {
        "Title": "BARTI",
        "Description": `Skill training and entrepreneurship development programme for 240 SC candidates in Pune and
Satara awarded by Dr. Babasaheb Ambedkar Research and Training Institute (BARTI), Pune`,
        "slug": "barti",
    },
    {
        "Title": "EU DST NET Skills",
        "Description": `Skill training programme on ‘Incubating New Emerging Technology (Net) Skills by Creating
Sustainable Models of Net Skill Packages’ in remote locations of Akole and Sangamner tehsils of
Ahmednagar District of Maharashtra.`,
        "slug": "eu-dst-net-skills",
    },
    {
        "Title": "Technology Based Skill Training Projects",
        "Description": "The Science and Technology Park, Pune, in collaboration with various organizations, conducted skill training programs for 240 SC candidates and differently-abled individuals in Pune, Satara, and Sangli.",
        "slug": "technology-based-skill-training-projects",
    },
    {
        "Title": "Skill Training for Differently Abled Persons",
        "Description": `Skill Training and Entrepreneurship Development Programmes have been the forte of Science and
Technology Park, Pune with numerous training programmes conducted successfully over the past
20+ years.`,
        "slug": "skill-training-for-differently-abled-persons",
    },
]

const SkillTraining = () => {
    return (
        <>
            <Banner title={"Skill Training"} />
            <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Typography variant="h4" color='#152265' fontWeight="600" align="center" mb='1rem' gutterBottom>
                        Introduction
                    </Typography>
                    <Typography variant="body1" paragraph textAlign={'justify'}>
                        High-end skill development in science and technology serves as a powerful tool to enhance the employability and productivity of youth. The initiative also emphasizes capacity building among marginalized youth, improving their employability and income-generation potential. Skill development programs enable participants to secure wage-based employment, while entrepreneurial training fosters the establishment of micro, small, and medium enterprises (MSMEs), creating job opportunities within the community.
                        <br />
                        <br />
                        Additionally, these programs provide post-training handholding support to ensure a smooth transition to employment or business establishment. This comprehensive approach significantly improves the success rate, contributing to sustainable economic growth and social upliftment.
                        <br />
                        <br />
                        Skill Training and Entrepreneurship Development Programmes have been the forte of Science and Technology Park, Pune with numerous training programmes conducted successfully over the past 25+ years.
                        <br />
                        <br />
                        Scitech Park, Pune is an Empanelled Training Partner of Ministry of Social Justice and Empowerment, Govt. of India and  Skill Council for Persons with Disability as Training Provider (TP) under the provisions of NSDC (National Skill Development Corporation), DEPwD (Department of Empowerment for Persons with Disabilities) and SCPwD (Skill Council of Persons with Disability) schemes or any other scheme of Government of India or State mission being implemented by NSDC/DEPwD through SCPwD or directly by SCPwD for persons with disability;
                        <br />
                        <br />
                        These programs are sponsored by various agencies both government and corporate sector through CSR.
                    </Typography>
                </Container>

                <Grid
                    container
                    mt="4rem"
                    justifyContent={"center"}
                    // alignItems={"center"}
                    sx={{
                        display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                        gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                        px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "12rem" },
                    }}
                >
                    {staticData?.map((item) => (
                        <SkillCard key={item?.Title} item={item} />
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default SkillTraining;