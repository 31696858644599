import React, { useState } from 'react';
import { Card, Box, Typography, Button, Divider } from '@mui/material';

export default function GeoinformaticsCard({ item }) {
    const [showFullText, setShowFullText] = useState(false);

    const toggleText = () => setShowFullText(!showFullText);

    const maxLength = 180;

    const isLongDescription = item.Description.length > maxLength;

    const truncatedDescription = isLongDescription && !showFullText
        ? `${item.Description.slice(0, maxLength)}...`
        : item.Description;

    return (
        <Card
            sx={{
                width: 350,
                height: 310,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.05)',
                },
                backgroundColor: '#1C2B77',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* Title Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: '2px 12px',
                    borderRadius: '4px',
                    mb: 1,
                    mt: 1,
                    width: '100%',
                }}
            >
                <Box
                    component="img"
                    src={`${item?.Images?.[0]}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.Title}
                    sx={{
                        width: 80,
                        height: 80,
                        borderRadius: '50%',
                        // mr: 2,
                    }}
                />
                <Typography
                    variant="h5"
                    mx={2}
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}
                >
                    {item.Title}
                </Typography>
            </Box>

            {/* Description Section */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: '1rem 2rem',
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '15px',
                        lineHeight: 1.6,
                        textAlign: 'justify',
                        mb: 1,
                        mt: 1,
                    }}
                    color="textSecondary"
                >
                    {truncatedDescription}
                    {/* {isLongDescription && (
                        <Button
                            sx={{
                                fontSize: '14px',
                                textTransform: 'none',
                                color: '#0275d8',
                                ml: 1,
                            }}
                            onClick={toggleText}
                        >
                            {showFullText ? 'View Less' : 'View More'}
                        </Button>
                    )} */}
                </Typography>
            </Box>

            {/* Footer Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    // py: '1rem',
                    borderRadius: '4px',
                    flexDirection: 'row',
                    mt: 'auto',
                    width: '100%',
                }}
            >
                {/* <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            fontWeight: 'bold',
                        }}
                    >
                        Explore
                    </Typography>
                </Box>

                <Divider
                    orientation="vertical"
                    sx={{
                        height: '40px',
                        borderColor: '#fff',
                        mx: 2,
                    }}
                /> */}

                <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Button
                        sx={{
                            fontSize: '14px',
                            textTransform: 'none',
                            color: 'white',
                        }}
                        href={`geoinformatics/${item.slug}`}
                        target="_self"
                    >
                        Read More
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}
