import React from 'react';
import {
    Card, Typography,
    CardMedia,
    CardContent,
    Button,
} from '@mui/material';

export default function CIIICentresCard({ item }) {

    return (
        <Card
            sx={{
                maxWidth: "100%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                color: "#000",
                height: "100%",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
            }}
        >    
            <CardMedia
                component="img"
                image={item?.image}
                alt={item?.name}
                sx={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "10px 10px 0 0",
                }}
            />
            <CardContent
                sx={{
                    textAlign: "left",
                    padding: "0.5rem 1rem",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "bold",
                        color: "#1c2b78",
                    }}
                >
                    {item?.name}
                </Typography>
            </CardContent>
            <CardContent
                sx={{
                    flexGrow: 1,
                    padding: "0.5rem 1rem",
                    textAlign: "justify",
                }}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ lineHeight: 1.5 }}
                >
                    {item?.location}
                </Typography>
            </CardContent>
            <CardContent
                sx={{
                    padding: "0.5rem 1rem",
                    textAlign: "right",
                }}
            >
                <Button
                    sx={{
                        color: "#1c2b78",
                        textTransform: "none",
                        padding: 0,
                        minWidth: 0,
                        height: 'auto',
                        fontWeight: 500,
                        "&:hover": {
                            color: "#152265",
                        },
                    }}
                    onClick={() => window.open(item?.website, "_blank")}
                >
                    Visit Website →
                </Button>
            </CardContent>
        </Card>
    );
}
