import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Link,
    Modal,
    Box,
    CardMedia,
    IconButton,
    Tooltip
} from '@mui/material';
import { PictureAsPdf, Close } from '@mui/icons-material';

function BlogCard({ docUrl, item }) {
    const [showFullText, setShowFullText] = useState(false);
    const [openImage, setOpenImage] = useState(false);

    const toggleText = () => setShowFullText(!showFullText);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    const maxLength = 80;
    const isLongDescription = item.Description?.length > maxLength;
    const truncatedDescription = isLongDescription && !showFullText
        ? `${item.Description.slice(0, maxLength)}...`
        : item.Description;

    const hasOnlyImage = item?.Images?.length === 1 && !item.Description;

    return (
        <>
            {hasOnlyImage ? (
                <Card
                    sx={{
                        width: 385,
                        height: 410,
                        position: 'relative',
                        boxShadow: 0,
                        borderRadius: 4,
                        overflow: 'hidden',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        objectPosition: 'top',
                        '& .MuiCardMedia-root': {
                            height: item?.News_date === '0000-00-00' ? '100%' : '80%',
                            objectFit: 'cover',
                        },
                    }}
                    onClick={handleOpenImage}
                >
                    <Tooltip title="Click to view full image">
                        <CardMedia
                            component="img"
                            src={`${docUrl}${item?.Images?.[0]}?w=385&h=385&fit=crop&auto=format`}
                            alt={`Image of ${item.Title}`}
                            sx={{
                                width: '100%',
                                height: 600,
                                objectFit: 'cover',
                                objectPosition: 'top',
                                borderRadius: '3rem',
                                padding: '1rem',
                            }}
                        />
                    </Tooltip>
                    {(item?.News_date === '0000-00-00') || (item?.News_date) && (
                        <CardContent sx={{ p: "0.2rem 1.2rem" }}>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                {new Date(item.News_date).toLocaleDateString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                })}
                            </Typography>
                        </CardContent>
                    )}
                </Card>
            ) : (
                <Card
                    sx={{
                        maxWidth: 385,
                        p: "1rem 1.2rem",
                        boxShadow: 0,
                        borderRadius: 4,
                        backgroundColor: '#fafafa',
                        overflow: 'hidden'
                    }}
                >
                    <CardMedia
                        component="img"
                        height="190"
                        image={`${docUrl}${item?.Images?.[0]}?w=164&h=164&fit=crop&auto=format`}
                        alt={`Image of ${item.Title}`}
                        sx={{ borderRadius: "1rem" }}
                    />
                    <CardContent sx={{ p: 0, pt: 3 }}>
                        {
                            item?.News_date &&
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                {new Date(item.News_date).toLocaleDateString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                })}
                            </Typography>
                        }
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {item.Title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" textAlign={'justify'}>
                            {truncatedDescription}
                            {isLongDescription && (
                                <Link
                                    component="button"
                                    onClick={toggleText}
                                    sx={{ ml: 1, fontSize: '0.9rem' }}
                                >
                                    {showFullText ? 'Read less' : 'Read more'}
                                </Link>
                            )}
                        </Typography>
                        {item?.Pdf && (
                            <Link
                                href={`https://mystpbucket.s3.ap-south-1.amazonaws.com/${item?.Pdf}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ mt: 2, display: 'block', color: '#0275d8' }}
                            >
                                View <PictureAsPdf />
                            </Link>
                        )}
                    </CardContent>
                </Card>
            )}

            <Modal open={openImage} onClose={handleCloseImage}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        outline: 'none',
                        position: 'relative'
                    }}
                >
                    <img
                        src={`${docUrl}${item?.Images?.[0]}?w=800&h=800&fit=crop&auto=format`}
                        alt={`Expanded view of ${item.Title}`}
                        style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '1rem' }}
                    />
                    <IconButton
                        onClick={handleCloseImage}
                        sx={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            borderRadius: '50%',
                            padding: '8px',
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
            </Modal>
        </>
    );
}

export default BlogCard;