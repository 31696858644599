import { Box, Card, CardMedia, IconButton, Tooltip, Modal } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material';

const ChandarCard = ({ item }) => {

    const [openImage, setOpenImage] = useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);

    return (
        <>
            <Card
                sx={{
                    width: 350,
                    height: 280,
                    position: 'relative',
                    boxShadow: 0,
                    borderRadius: 4,
                    overflow: 'hidden',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    objectPosition: 'top',
                    '& .MuiCardMedia-root': {
                        height: '100%',
                        objectFit: 'cover',
                    },
                }}
                onClick={handleOpenImage}
            >
                <Tooltip title="Click to view full image">
                    <CardMedia
                        component="img"
                        src={item?.image}
                        alt={`Image of `}
                        sx={{
                            width: '100%',
                            height: 600,
                            objectFit: 'cover',
                            objectPosition: 'top',
                            borderRadius: '3rem',
                            padding: '1rem',
                        }}
                    />
                </Tooltip>
            </Card>

            <Modal open={openImage} onClose={handleCloseImage}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        outline: 'none',
                        position: 'relative'
                    }}
                >
                    <img
                        src={item?.image}
                        alt={`Expanded view of `}
                        style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '1rem' }}
                    />
                    <IconButton
                        onClick={handleCloseImage}
                        sx={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            borderRadius: '50%',
                            padding: '8px',
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
            </Modal>
        </>
    )
}

export default ChandarCard