import React from "react";
import { Box, Typography } from "@mui/material";
import "../../fonts/fonts.css"; // Ensure this contains your @font-face rule for custom fonts

const Banner = ({
    title,
    fontFamily = "sans-serif", // Default font family
    minHeight = { xs: "20vh", md: "18vh", lg: "16vh" },
    fontSize = { xs: "2rem", md: "3rem", lg: "3rem" },
    letterSpacing = "0px",
}) => {
    console.log("Environment Variables:", process.env);
    console.log("STP URL:", process.env.REACT_APP_STP_URL);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: minHeight,
                // backgroundImage: "linear-gradient(to top, #243078, rgba(0, 0, 0, 0.5))",
                // backgroundImage: "linear-gradient(#243078, rgba(0, 0, 0, 0.5))",
                backgroundColor: "#243078",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // py: 2,
                color: "white",
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                {/* <Box
                    sx={{
                        width: "50%",
                        // display: "flex",
                        ml: "-6rem",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={`${process.env.REACT_APP_STP_URL}/businessSubPages/NIDHI-EIR+LOGO.png`}
                        alt="startup"
                        style={{
                            width: "80%",
                            maxWidth: "400px",
                            // borderRadius: "5%",
                        }}
                    />
                </Box> */}
                <Typography
                    variant="h1"
                    fontSize={fontSize}
                    fontWeight="600"
                    sx={{
                        fontFamily: fontFamily,
                        letterSpacing: letterSpacing,
                    }}
                >
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default Banner;
