import React from "react";
import { Box, Typography } from "@mui/material";
import '../../fonts/fonts.css';
import './index.css'

const imgData = [
    `${process.env.REACT_APP_STP_URL}/csrStaticData/3.jpg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/5.jpg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/6.jpeg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/7.jpg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/8.jpg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/9.jpg`,
    `${process.env.REACT_APP_STP_URL}/csrStaticData/2.jpg`,
];

const CsrBanner = ({
    title,
    fontFamily = "sans-serif",
    minHeight = { xs: "40vh", md: "30vh", lg: "45vh" },
    fontSize = { xs: "1.8rem", md: "2.8rem", lg: "3.8rem" },
}) => {
    return (
        <Box
            sx={{
                maxWidth: "100%",
                mx: "auto",
                minHeight: { xs: "30vh", lg: "84vh" },
                overflow: "hidden",
            }}
        >
            <div
                id="geoinformaticCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="3000"
            >
                <div className="carousel-indicators">
                    {imgData.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#geoinformaticCarousel"
                            data-bs-slide-to={index}
                            className={index === 0 ? "active" : ""}
                            aria-current={index === 0 ? "true" : undefined}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {imgData.map((image, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                // minHeight: "84vh",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                                    zIndex: 1,
                                }}
                            ></div>
                            <div
                                style={{
                                    zIndex: 2,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "100%",
                                    textAlign: "center",
                                    paddingTop: "32.5vh",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    fontSize={fontSize}
                                    fontWeight="600"
                                    letterSpacing="1.5px"
                                    sx={{
                                        color: "white",
                                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    {title}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#geoinformaticCarousel"
                    data-bs-slide="prev"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        borderRadius: "50%",
                        width: "3rem",
                        height: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "1rem",
                        zIndex: 1,
                    }}
                >
                    <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                        style={{
                            textShadow: "2px 2px 5px rgba(0, 0, 0, 1.8)",
                        }}
                    ></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#geoinformaticCarousel"
                    data-bs-slide="next"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        borderRadius: "50%",
                        width: "3rem",
                        height: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "1rem",
                        zIndex: 1,
                    }}
                >
                    <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                        style={{
                            textShadow: "-2px 2px 5px rgba(0, 0, 0, 1.8)",
                        }}
                    ></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </Box>
    );
};

export default CsrBanner;
