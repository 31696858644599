import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import AwardsCard from "./AwardsCard";
import AwardBanner from "./AwardBanner";

const AwardPage = () => {
    const [awards, setAwards] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/awards/getall"
                );
                setAwards(response?.data?.result || []);
            } catch (error) {
                console.error("Error fetching awards:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAwards();
    }, []);

    const renderAwards = (receivedBy, limit = 6) => {
        return awards
            .filter((award) => award.Received_by === receivedBy)
            .slice(0, limit)
            .map((award) => (
                <AwardsCard key={award?.Award_id} docUrl={docUrl} item={award} />
            ));
    };

    const renderOtherAwards = (limit = 6) => {
        return awards
            .filter((award) => award.Received_by !== "Science and Technology Park")
            .slice(0, limit)
            .map((award) => (
                <AwardsCard key={award?.Award_id} docUrl={docUrl} item={award} />
            ));
    };

    return (
        <>
            <AwardBanner />
            <Box
                sx={{
                    maxWidth: "100%",
                    paddingBottom: "50px",
                    backgroundColor: "#d7e3fc",
                }}
            >
                {/* Section: Awards received by Science and Technology Park */}
                <Typography
                    variant="h1"
                    backgroundColor="#1c2b78"
                    color="white"
                    textAlign={"center"}
                    fontSize={{ xs: "2rem", md: "2.5rem", lg: "3.5rem" }}
                    fontWeight={"600"}
                    mt={"1.8rem"}
                    py="3.5rem"
                >
                    Awards received by STP
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : awards.length > 0 ? (
                    <Grid
                        container
                        mt="6rem"
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {renderAwards("Science and Technology Park")}
                    </Grid>
                ) : (
                    <Grid
                        container
                        py='4rem'
                        style={{
                            display: "grid",
                            placeItems: "center",
                            // paddingTop: "50px",
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt="No data found"
                        />
                        <Typography textAlign="center" mt={2}>
                            No data available.
                        </Typography>
                    </Grid>
                )}

                <Box textAlign="center" my={6}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "white",
                            color: "#0a2d7f",
                            padding: "1rem 3rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: '10px',
                            ":hover": {
                                backgroundColor: "#f5f5f5",
                            },
                        }}
                        onClick={() => window.open(`/awards-by-stp`, "_self")}
                    >
                        More Awards by STP
                    </Button>
                </Box>

                {/* Section: Awards received by Incubatees */}
                <Typography
                    variant="h1"
                    textAlign={"center"}
                    backgroundColor="#1c2b78"
                    color="white"
                    fontSize={{ xs: "2rem", md: "2.5rem", lg: "3.5rem" }}
                    fontWeight={"600"}
                    mt={"1.8rem"}
                    py="3.5rem"
                >
                    Awards received by Incubatees
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : awards.length > 0 ? (
                    <Grid
                        container
                        mt="6rem"
                        justifyContent={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {renderOtherAwards()}
                    </Grid>
                ) : (
                    <Grid
                        container
                        py='4rem'
                        style={{
                            display: "grid",
                            placeItems: "center",
                            // paddingTop: "50px",
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt="No data found"
                        />
                        <Typography textAlign="center" mt={2}>
                            No data available.
                        </Typography>
                    </Grid>
                )}

                <Box textAlign="center" mt={6}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "white",
                            color: "#0a2d7f",
                            padding: "1rem 3rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: '10px',
                            ":hover": {
                                backgroundColor: "#f5f5f5",
                            },
                        }}
                        onClick={() => window.open(`/awards-by-incubatees`, "_self")}
                    >
                        More Awards by Incubatees
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AwardPage;
