import React from 'react';
import Banner from './Banner';
import { Box, Container, Typography } from '@mui/material';

const STPDonations = () => {
    return (
        <>
            <Banner title={"STP Donations"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    {/* <Typography variant="h4" fontWeight="600" gutterBottom>
                        Lorem ipsum
                    </Typography> */}
                    <Typography variant="body1" paragraph>
                        Section 135 of the Companies Act of 2013 allows companies to contribute to Technology Incubators located in academic institutions that are approved by the central government.
                        Science and Technology Park, Pune being a Technology Business Incubator established by the Department of Science and Technology, Government of India can receive CSR funds as donations from Corporates.
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default STPDonations