import React from 'react';
import { Box, Grid, Typography, Divider, List, ListItem } from '@mui/material';

export default function GrowthLabIncubation() {
    return (
        <Box sx={{ backgroundColor: '#ffffff', px: { xs: 3, sm: 6, md: 16 }, py: 4, pb: '3rem' }}>
            <Grid container alignItems="center" justifyContent="center">
                {/* Title Section */}
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            mt: 2,
                            color: "#364356",
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: { xs: '1.5rem', md: '2rem' }
                        }}
                    >
                        GROWTH LAB INCUBATION PROGRAM SELECTION CRITERIA
                    </Typography>
                    <Divider
                        sx={{
                            width: '10%',
                            mx: 'auto',
                            mt: 2,
                            mb: 3,
                            borderBottomWidth: 1,
                            backgroundColor: '#364356',
                        }}
                    />
                </Grid>

                {/* Description Section */}
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.1rem' },
                            lineHeight: 1.6,
                            mb: 3,
                            textAlign: { xs: 'center', sm: 'left' },
                        }}
                    >
                        Growth Lab incubates high-tech start-ups through its incubation program.
                        <br />
                        The selection for this program is quite strict and structured, focusing on the following criteria:
                    </Typography>
                </Grid>

                {/* Criteria Section */}
                <Grid item xs={12}>
                    <List sx={{ paddingLeft: { xs: '10px', sm: '20px' }, listStyleType: 'disc' }}>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Innovativeness of technology/idea
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Growth Potential
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Financial Viability
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Business Plan
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Social Impact
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                            Experience, Qualification and Team
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}
