import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import EventCard from "./EventCard";
import pattern from "../../Media/Pattern.png";

const UpcomingEvents = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            try {
                const response = await axios.post(
                    "https://api.scitechpark.org.in/events/getUpcomingEvents"
                );
                setEvents(response?.data?.events || []);
            } catch (error) {
                console.error("Error fetching upcoming events:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUpcomingEvents();
    }, []);

    return (
        <>
            {/* Banner Section */}
            <Box
                sx={{
                    width: "100%",
                    minHeight: { xs: "60vh", md: "35vh", lg: "50vh" },
                    backgroundImage: `url(https://localmedia.org/wp-content/uploads/2021/06/events-1.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 4,
                    color: "white",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "1200px",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="h1"
                        fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem" }}
                        fontWeight="600"
                        sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                    >
                        Upcoming Events
                    </Typography>
                </Box>
            </Box>

            {/* Events Section */}
            <Box
                sx={{
                    py: 10,
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} py={{ xs: 1, md: 2 }}>
                    <img height="41px" src={pattern} alt="pattern" />
                    <Typography
                        variant="h6"
                        backgroundColor="#eaeff3"
                        fontSize={"1.6rem"}
                        fontWeight="bold"
                        px="0.5rem"
                    >
                        Explore Upcoming Events
                    </Typography>
                </Grid>

                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : events.length > 0 ? (
                    <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        sx={{
                            px: { xs: "3rem", sm: "1rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {events.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item?.Event_id}>
                                <EventCard docUrl={docUrl} item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid
                        container
                        style={{
                            display: "grid",
                            placeItems: "center",
                            paddingTop: "50px",
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt="No data found"
                        />
                        <Typography textAlign="center" mt={2}>
                            No data available.
                        </Typography>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default UpcomingEvents;
