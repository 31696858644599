import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const PlantationForSeniorsData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-6.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-7.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-8.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-9.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-10.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-11.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-12.webp`
    },
]

const PlantationForSeniors = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Plantation Programme for Senior Citizens from Shantivan Care Centre and Abhal Maya Old Age Home from Pune
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PlantationForSeniors/PPOA-Cover-Image.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    In order to support the senior citizens of Pune and to give them a pleasant experience of planting trees at a village which will be eventually a part of integrated village development plan, Science and Technology Park, Pune with the support of One Less in collaboration with Mukul Madhav Foundation, Pune, has carried out a plantation programme for senior citizens of two old age homes namely Shantivan Care Centre and Abhal Maya Old Age Home from Pune on Monday, 13th December, 2021 at Gram Panchayat Office, Panawadi village, Purandar Taluka, Pune.
                    <br /> <br />
                    The dignitaries present at the event were Mrs. Nalini Haribhau Lole, President, Purandar Panchayat Samiti, Ms. Anita Devkar from Shantivan Care Centre, Mr. Ramesh Sabu from Aabhal Maya Old Age Home, Mr. Babalu Mokale and Mr. Jitendra Jadhav from Mukul Madhav Foundation, Pune, Mr. Aabasaheb Lole, Sarpanch, Panawadi , Mr. Mauli Bhise, Deputy Sarpanch, Panawadi , Mr. Haribhau Lole, Mrs. Sushma Bhise, Mr. Sandeep Bhise from Panawadi, Mr. Vaibhav Nimgire and Mr. Sanmati Shetgar from Science and Technology Park, Pune, Members of Waghzai Mata Shetmal Utpadak Company, Panawadi and Members of Adarsh Mahila Bachat Gat, Panawadi.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {PlantationForSeniorsData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default PlantationForSeniors;
