import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const PanwadiPlantationIIIData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/1-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/2-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/3-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/4-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/5-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/6-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/7-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/8-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/9-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/10-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/11-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/12-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/13-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/14-822.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/15-822.webp`
    },
]

const PanwadiPlantationIII = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Inauguration of Phase III Plantation Programme held on Friday, 22nd July, 2022 at Panawadi Village, Tal. Purandar, Dist. Pune
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-III/cover-822.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Aligned with the United Nations 17 Sustainable Development Goals and as a part of Science and Technology Park’s ambitious mission of planting 50,000 fruit trees within three years to enhance ecology as well as economy and nutrition status of the population of Panawadi, the <strong>Phase III plantation programme has been initiated in the month of July, 2022</strong> with the wholehearted support of One Less in collaboration with Finolex Industries Ltd, Pune and its CSR partner Mukul Madhav Foundation, Pune.
                    <br /> <br />
                    The Phase III plantation programme was inaugurated on Friday, 22nd July, 2022 at Pathar Plantation site, Vadachi Wadi, village Panawadi, Tq. Purandar, Dist. Pune. The programme was initiated by planting the first batch of Mango saplings at the site. Total 500 mango saplings will be planted by the villagers of Panawadi at Pathar site. The dignitaries present for the plantation programme were Ms. Puja Jahgirdar, Finolex Industries Ltd., Pune; Mr. Jitendra Jadhav, Mukul Madhav Foundation, Pune, Mr. Yogesh Rokade, Mukul Madhav Foundation, Pune, Mr. Sanmati Shetgar, Science and Technology Park, Pune, Mr. Vaibhav Nimgire, Science and Technology Park, Pune, Mr. Vigyan Arya, Science and Technology Park, Pune, Mrs. Nalini Lole, President, Panchayat Samiti, Purandar, Mr. Abbasaheb Lole, Sarpanch, Panawadi, Mr. Haribhau Lole, Mr.Sandip Bhise , Mr. Mauli Bhise, Mr. Hanumant Lole, Mr. Deepak Bhise, Mr. Kiran Lole, Mrs. Sushma Bhise, Mrs. Anita Lole, Ms. Sonali Bhise, Ms. Neelam Bhise, Panawadi.
                </Typography>

            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {PanwadiPlantationIIIData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default PanwadiPlantationIII;
