import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const PanawadiPlantationData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/6.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/7.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/8.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/9.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/10.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/11.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/12.webp`
    },
]

const PanawadiPlantation = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Panawadi Plantation Project
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanawadiPlantation/Plantation-video-Intro-slide.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    We at Scitech Park, Pune have adopted the village of Panawadi and are initiating various projects aimed at cultivating the holistic development of this region’s social and natural ecosystem.
                    <br />
                    <br />
                    One of our projects, completed on the 30th of July 2020, detailed the distribution of 2400 saplings of fruit bearing trees to the local farmer of Panawadi.
                    <br />
                    <br />
                    This project has been supported by Finolex Industries Ltd., Pune and their CSR partner Mukul Madhav Foundation, Pune.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {PanawadiPlantationData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

            <Grid container spacing={2} py={4} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }} >
                {/* {videos.map((video, index) => ( */}
                    <Grid item xs={12} md={5} >
                        <Box
                            m={'auto'}
                            sx={{
                                position: "relative",
                                width: { xs: 350, md: 510 },
                                height: { xs: 180, md: 280 },
                                overflow: "hidden",
                                borderRadius: "10px",
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <iframe
                                src={"https://www.youtube.com/embed/60pC3UYInGg"}
                                title={'title'}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></iframe>
                        </Box>
                    </Grid>
                {/* ))} */}
            </Grid>

        </Box>
    )
}

export default PanawadiPlantation;
