import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const TwoPedalBoatsData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-01.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-02.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-03.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-04.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-05.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-06.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-07.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-08.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-09.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-10.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-11.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-12.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-13.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-014.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-15.webp`
    },
]

const TwoPedalBoats = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Two pedal boats launched in Panawadi by Scitech Park, Pune on 28th March, 2022 at Patzai Dam, Bhisewadi, Panawadi
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/TwoPedalBoats/pbl-cover.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Village Panawadi, adopted by Scitech Park is taking strong strides to evolve into a self-sustaining Model Village, and working to provide employment and business opportunities to its residents. One such activity planned is the Introduction of Boating in the water bodies of Panawadi. FICCI FLO, Pune Chapter and Ayugen Biosciences Pvt. Ltd. Scitech Park, Pune, Start-up have extended their generous support for procurement of two 4-seater pedal boats to encourage and empower women of the Panawadi in becoming economically independent with a regular and sustainable business that will grow with time with the popularity of Panawadi as a tourist destination.
                    <br /> <br />
                    Two Pedal Boats were launched on 28th March 2022 at Patzai Dam, Bhisewadi, Panawadi at the hands of  Shri. Pratap Pawar, Chairman & MD, Sakal Papers Pvt. Ltd., Pune & Vice President, Scitech Park, Pune and Shri. Dilip Band, IAS (Retd.), President, Scitech Park, Pune. Two girls and two boys from the village were identified and trained by the Scitech Park team in special boat skills and emergency rescue techniques prior to the launch of the pedal boats.
                    <br /> <br />
                    The dignitaries present for the launch of the pedal boat were Ms. Usha Poonawalla, Chairperson, FICCI FLO, Pune Chapter; Ms. Noopur Pawar, Member, FICCI FLO, Pune Chapter, Ms. Pooja Anand, Member, FICCI FLO, Pune Chapter, Ms. Mariam Daruwala, Member, FICCI FLO, Pune Chapter; Dr. Gayatri Tewari, Member, FICCI FLO, Pune Chapter; Ms. Senorita Issac, FICCI FLO, Pune Chapter, Dr. Rajendra Jagdale, DG & CEO, Science and Technology Park, Pune; Mr. Gurvinder Singh, Mentor, Blogger and Entrepreneur, Pune; Mr. Paresh Parekh, MD, Autofina Ltd., UK; Mr. Badri Poonawalla, Interior Designer, Artist, Pune; Dr. John Kurien, Professor, Centre for Development Studies, Kerala; Mr. Hector Andrade, Educationist, Pune, Mr. Arvind Patole, Adventure Sports Expert and wildlife Photographer, Pune; Mr. Manish Pungliya, Entrepreneur and Incubatee Company of Scitech Park, Pune; Mrs. Supriya Sankpal, Entrepreneur,Pune; Mr. Subhash Kankariya, Director, Kankariya Corporation, Pune; Mr. Abasaheb Lole, Sarpanch, Panawadi, Mrs. Nalini Lole, President, Panchayat Samiti, Panawadi; Dr. Shubhangi Jadhav, Science and Technology Park, Pune; Mr. Vigyan Arya, Science and Technology Park, Pune; Mr. Vaibhav Nimgire, Science and Technology Park, Pune; Mr. Sanmati Shetgar, Science and Technology Park, Pune; Mrs. Sushma Bhise, Mrs. Neelam Bhise, Mr. Sandip Bhise, Mr. Mauli Bhise, Mr. Haribhau Lole , Mr. Lakhan Dhivar and members of Adarsh Mahila Bachat Gat and Waghzai Mata Shetmal Utpadak Company., Panawadi.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {TwoPedalBoatsData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default TwoPedalBoats;
