import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const RiceDeHuskingData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/6.webp`
    },
]

const RiceDeHusking = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Innovative ‘Rice De-Husking Machine’ successfully inaugurated by Scitech Park, on Wednesday, 04th March 2020 at Panvadi village, Tq. Purandhar, Dist. Pune, Maharashtra
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/RiceDeHusking/IMG_20200304_100305_result.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    With an aim to empower women of Panvadi with a sustainable business model,
                    <strong>‘Rice De-Husking Machine’ was successfully inaugurated by Scitech Park, on Wednesday, 04th March, 2020 at Panvadi village, Tq. Purandhar, Dist. Pune.</strong>
                    The machine has been specially designed and manufactured by one of the startups promoted under Department of Science and Technology, Government of India’s SEED TARA Scheme (Technological Advancement for Rural Areas) and is developed at Vigyan Ashram, Pabal.
                    The machine works on single-phase supply, which is available throughout the year in the village, and it is designed with minimum workmanship for easy repair and maintenance.
                    <br />
                    <br />
                    This would <strong>be solely run by the women SHG in the village namely Adarsh Mahila Bachatgat</strong>, who would be responsible for processing, packing, and selling of the nutrient-rich organic premium quality brown rice cultivated in the village and is in high demand in the market.
                    This project <strong>has been supported by FICCI FLO Pune Chapter.</strong>
                    Through this, the local farmers can extend their high-quality brown rice and introduce it in the demanding market and explore new agri-business opportunities.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {RiceDeHuskingData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default RiceDeHusking;
