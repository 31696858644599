import React from 'react'
import { Box, Container, Typography } from '@mui/material';


const SchoolLibrary = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Inauguration of School Library at Panawadi Village, Taluka Purandar, Dist. Pune on 02nd October, 2019
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SchoolLibrary/School-Library_Panawadi_result-scaled.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Science and Technology Park, Pune has adopted Panawadi village for its complete socio-economic and ecological development and to transform it into a model replicable village with highest United Nations Sustainable Development Goals.
                </Typography>
            </Container>

        </Box>
    )
}

export default SchoolLibrary;
