import React from "react";
import { Box } from "@mui/material";
import BusinessIncubationContent from "./BusinessIncubationContent";
import TitleSection from "./TitleSection";

export default function BusinessIncubation() {
  return (
    <Box
      sx={{
        backgroundColor: "#d7e3fc",
        py: 4,
        px: 2,
      }}
    >
      <TitleSection />
      <BusinessIncubationContent />
    </Box>
  );
}
