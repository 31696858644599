import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const MedicinalPlantationData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/1_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/2_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/3_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/4_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/5_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/6_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/7_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/8_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/9_pa21.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/10_pa21_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/11_pa21_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/12_pa21_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/13_pa21_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/14_pa21_result.webp`
    },
]

const MedicinalPlantation = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Training Programme on Conservation and Cultivation of Medicinal Plants conducted on Wednesday, 18th August, 2021 at Panawadi Village, Taluka Purandar, Dist. Pune
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/MedicinalPlantation/cover_pa21_result.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    In recent years, cultivation of medicinal plants has started gaining momentum. Medicinal plants are not only a major resource base for the traditional medicine & herbal industry but they also provide livelihood and health security to a large segment of the Indian population. Considering the geography, water, and land availability, Panawadi village has a huge potential for sustainable medicinal plantations across its four wadis.
                    <br />
                    <br />
                    Science and Technology Park, Pune in association with Regional-Cum-Facilitation Center, Western Region (RCFC-WR), National Medicinal Plant Board (NMPB), Ministry of Ayush, Govt. of India, Savitribai Phule Pune University, Pune organized a training programme on Preservation and Cultivation of Medicinal Plants on Wednesday, 18th August, 2021 for the farmers of its adopted village; Panawadi.
                    <br />
                    <br />
                    The dignitaries present for the programme were Dr. K.N. Dhumal, Dy. Director, RCFC-WR, NMPB, SPPPU, Pune, Mr. Vigyan Arya, Science and Technology Park, Mrs. Nalini Lole, President, Panchayat Samiti, Panawadi; Mrs. Sunita Sapkal, Gramsevak, Panawadi; Mr.Aaba Lole, Sarpanch, Panawadi; Mr. Haribhau Lole; Mr. Sachin Salunke, Paani Foundation, Panawadi, Mr. Sandip Bhise; Mrs. Sushma Bhise; Mr. Sanmati Shetgar, Science and Technology Park, Pune; Mr. Vaibhav Nimgire, Science and Technology Park, Pune and farmers of Panawadi village.
                    <br />
                    <br />
                    The programme facilitated farmers with information on the selection of a variety of medicinal saplings, land, soil, and water requirements, maintenance and plant care, harvesting, current market demand, value, supply, etc. The information of important medicinal plants viz; <u><i>Tinospora cordifolia, Premna serratifolia, Rauvolfia sp, Croton tiglium, Plumbago zeylanica, Vitex negundo, Ficus religiosa</i></u>, etc., which are suitable for cultivation at Panawadi village was shared during the programme and sample saplings were also distributed among the farmers.
                    <br />
                    <br />
                    The farmers are very keen to support medicinal plantations and they have agreed to plant around 5000 medicinal plants across the available land and create <strong>a unique medicinal plantation belt within the village in coming years.</strong> This initiative under Scitech Park’s mission of 50,000 tree plantations in the village will ultimately stimulate the rural economy, create jobs and help maintain the critical ecosystem of the place and strengthen the climate resilience of the rural poor.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {MedicinalPlantationData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default MedicinalPlantation;
