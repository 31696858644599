import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const PanwadiPlantationIIData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/5_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/7_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/9_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/10_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/12_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/13_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/15_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/18_result.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/22_result.webp`
    },
]

const PanwadiPlantationII = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Inauguration of Phase II Plantation Programme at Panawadi Village, Taluka Purandar, Dist. Pune on 22nd June, 2021
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/PanwadiPlantation-II/cover-page_pj2021.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Science and Technology Park, Pune with an aim of socio-economic and ecological development of Panawadi village and convert it into a model replicable sustainable village with highest United Nations Sustainable Development Goals, has taken a mission of planting 50,000 fruit trees within three years as an effort to enhance ecology as well as economy and nutrition status of the Panawadi population.
                    <br />
                    <br />
                    Since last year, Scitech Park has already completed plantation of around 5000 saplings which were a mix of fruit trees, medicinal plants as well as varieties which are indigenous to the Western Ghats.
                    <br />
                    <br />
                    The Phase II plantation programme was inaugurated on Tuesday, 22nd June, 2021 at Pathar Plantation site, Vadachi Wadi, village Panawadi, Tq. Purandar, Dist. Pune. This was to initiate the plantation programme by planting the first batch of 60 fruit trees consisting of Guavas, Mangoes, Custard Apple, Black Plum, Jack Fruit and Indian Gooseberry. The varieties of saplings planted are as per the request made by the villagers and a total of 500 such saplings will be planted under this Phase II plantation programme.
                    <br />
                    <br />
                    The project has been jointly supported by OneLess, Finolex Industries Ltd.Pune, and its CSR partner Mukul Madhav Foundation, Pune. The dignitaries present for the plantation programme were Mr. Raj Louis, Head, Human Resource Department, Finolex Industries Ltd., Pune; Dr. Rajendra Jagdale, DG & CEO, Science and Technology Park, Pune; Dr. Prashant Varkey, Scientist, Zum Heilen Diagnostic & Therapeutics Pvt Ltd; Dr. Puja Kanwar, Group Director, Science and Technology Park, Pune; Ms. Aarti Sood, Manager Science and Technology Park, Pune; Mr. Jivraj Chole, Journalist, Mukul Madhav Foundation, Pune, Mr. Sanmati Shetgar, Science and Technology Park, Pune; Mr. Vaibhav Nimgire, Science and Technology Park, Pune; Mrs. Nalini Lole, President, Panchayat Samiti, Panawadi; Mrs. Sunita Sapkal, Gramsevak, Panawadi; Mr. Aaba Lole, Sarpanch, Panawadi; Mr. Haribhau Lole; Mr. Sandip Bhise; Mrs. Sushma Bhise; Mr. Anil Magar, members of Waghzai Mata Shetmal Utpadak Company, Panawadi.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {PanwadiPlantationIIData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default PanwadiPlantationII;
