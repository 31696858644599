import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

export default function CustomTabs() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
                sx={{
                    '.MuiTab-root': {
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#091242',
                        backgroundColor: 'transparent',
                        marginRight: '1rem',
                        '&:hover': {
                            backgroundColor: '#0912421A',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#F27C5C',
                            color: 'white',
                        },
                    },
                    '.MuiTabs-flexContainer': {
                        borderBottom: 'none',
                    },
                }}
            >
                <Tab
                    label="‘Convert Knowledge into Wealth’"
                    sx={{
                        width: '100%',
                        '& .MuiTab-wrapper': {
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                        },
                    }}
                />


                {/* <Tab label="Our Vision" />
                <Tab label="Our Mission" /> */}
            </Tabs>
            <Box sx={{ mt: 2 }}>
                {tabValue === 0 && (
                    <>
                        <Typography sx={{ color: '#666C89' }}>
                            Scitech Park’s key mandate includes:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#5A7184", textAlign: 'justify' }}>
                            <ul>
                                <li>Technology Acceleration and Commercialisation</li>
                                <li>Incubation</li>
                                <li>Science and Technology based advisory services to public and private organisations</li>
                                <li>Innovation ecosystem development and policy research</li>
                            </ul>
                        </Typography>
                        <Typography sx={{ color: '#666C89', textAlign: 'justify' }}>
                            In addition to our incubation program, Scitech Park is also a Satellite Policy Research Centre to the Ministry of Science and Technology, Govt. of India and we frequently assist the government for policy research and writing, technology forecasting and assessment and vision planning.
                        </Typography>
                    </>
                )}

                {tabValue === 1 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Content for Our Vision tab.
                    </Typography>
                )}
                {tabValue === 2 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Converting knowledge into wealth.
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
