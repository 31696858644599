import React, { useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import pattern from "../../Media/Pattern.png";
import mailIcon from "../../Media/mailIcon.png";
import phoneIcon from "../../Media/phoneIcon.png";
import timeIcon from "../../Media/timeIcon.png";
import axios from "axios";

export default function ContactForm() {
    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Phone_no: "",
        City: "",
        Query: "",
        User_role: "admin",
    });

    // console.log(formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "https://api.scitechpark.org.in/contact_us/add",
                formData
            );
            alert("Form submitted successfully");

            // Clear the form after successful submission
            setFormData({
                Name: "",
                Email: "",
                Phone_no: "",
                City: "",
                Query: "",
                User_role: "admin",
            });
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form");
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#152265",
                p: { xs: 1, md: "2rem 3rem" },
                borderRadius: 0,
                maxWidth: 800,
                margin: { xs: 2, md: "auto" },
                textAlign: "center",
            }}
        >
            <Grid mb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <img height="22px" src={pattern} alt="pattern" />
                <Typography
                    variant="subtitle2"
                    color="white"
                    backgroundColor="#1f2753"
                    fontWeight="500"
                    px="0.5rem"
                >
                    Contact
                </Typography>
            </Grid>
            <Typography variant="h4" sx={{ color: "#FFFFFF", fontWeight: 600, mb: 2 }}>
                Get in touch with us
            </Typography>
            {/* <Typography
                variant="body2"
                sx={{
                    color: "white",
                    px: { xs: 0, md: 10 },
                    mb: 2,
                    fontSize: "1rem",
                    textAlign: "center",
                    lineHeight: "1.5rem",
                }}
            >
                Leverage agile frameworks to provide a robust synopsis for strategy
                foster collaborative thinking to further the overall value.
            </Typography> */}

            <Grid container spacing={1} justifyContent="center" mb={1}>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <img
                                style={{
                                    width: '80%',
                                }}
                                src={mailIcon}
                                alt="mail"
                            />
                        </Box>
                        <Typography sx={{ color: "#FFFFFF", fontSize: "0.9rem", fontWeight: 500 }}>
                            stp@scitechpark.org.in
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <img
                                style={{
                                    width: '80%',
                                }}
                                src={phoneIcon}
                                alt="phone"
                            />
                        </Box>
                        <Typography sx={{ color: "#FFFFFF", fontSize: "0.9rem", fontWeight: 500 }}>
                            +91-20-25699206 / 9960000963
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <img
                                style={{
                                    width: '80%',
                                }}
                                src={timeIcon}
                                alt="time"
                            />
                        </Box>
                        <Typography sx={{ color: "#FFFFFF", fontSize: "0.9rem", fontWeight: 500 }}>
                            Mon - Fri 10:00 am - 06:00 pm
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} p={"1rem 2.5rem"} justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Your name*"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            InputProps={{
                                sx: {
                                    color: "white",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: "white",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email*"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            InputProps={{
                                sx: {
                                    color: "white",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: "white",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone Number*"
                            name="Phone_no"
                            value={formData.Phone_no}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            InputProps={{
                                sx: {
                                    color: "white",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: "white",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="City*"
                            name="City"
                            value={formData.City}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            InputProps={{
                                sx: {
                                    color: "white",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: "white",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Your Message"
                            name="Query"
                            value={formData.Query}
                            onChange={handleChange}
                            multiline
                            rows={2}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            InputProps={{
                                sx: {
                                    color: "white",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: "white",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: "#e88060",
                                color: "#fff",
                                padding: "16px 28px",
                                fontWeight: 500,
                                fontSize: "14px",
                                textTransform: "none",
                                borderRadius: 0,
                                ":hover": {
                                    backgroundColor: "#e87450",
                                },
                            }}
                        >
                            Submit Message
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
