import React from 'react';
import Banner from '../../Components/Banner';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CGRF from '../../Media/CGEF.png'
import GreenGlobe from '../../Media/greenGlobe.png'
import EcoHousing from '../../Media/ecoHousing.png'

const Environment = () => {
    return (
        <>
            <Banner title={"Environment"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    {/* <Typography variant="h4" fontWeight="600" gutterBottom>
                        Lorem ipsum
                    </Typography> */}
                    <Typography variant="body1" paragraph>
                        Scitech Park in Pune has been a torch bearer in the environmental sector for 30+ years, driving innovation and sustainable development. Through innovative technologies and products, it has focused on addressing critical environmental challenges, including renewable energy, solid waste management and eco-friendly technologies. By fostering collaborations with industries, academia, and government bodies, Scitech Park has implemented impactful solutions that promote green practices and sustainable livelihoods. Its initiatives have significantly contributed to environmental conservation, education, awareness, management and abating pollution making it a key player in India's journey towards a greener future.
                    </Typography>
                </Container>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    px: { xs: 2, md: 12 },
                    width: '100%',
                    maxWidth: '900px',
                    margin: 'auto',
                }}>
                <Paper

                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: { xs: '12px', md: '16px' },
                        marginTop: '2rem',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Environment Assessment
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184", }}>
                                <List sx={{ textAlign: 'center' }}>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="1. Environmental Impact Assessment (EIA) and Environment Management Plan (EMP) studies for developmental projects" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="2. Providing inputs for Protected Area (PA) Management"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="3. Monitoring of Physical and Biological Environment " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="4. Environmental Planning and Monitoring" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="5. Preparation of Eco-Development Plan" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="6. Watershed Management" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="7. Catchment and Command Area Development " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="8. Preparation of Dam Break Analysis and Disaster Management Plan" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="9. Preparation of Digital Databases" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="10. Preparation of Ecotourism Plans" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="11. Undertaking Conservation Action Programs " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="12. Providing Information Technology Support for Environmental Monitoring, Application and Product Development by using Remote Sensing (RS) and Geographical Information Systems (GIS) for land use – land cover study, mapping various attributes of environment and carrying out habitat suitability analysis for Wildlife Management " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="13.  Policy formulation and implementation etc." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="14.  Biodiversity Assessment" />
                                    </ListItem>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>


                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: { xs: '12px', md: '16px' },
                        marginTop: '2rem',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Education and Research
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184", }}>
                                <List sx={{ textAlign: 'center' }}>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="A unique ‘Environment Education’ program for teachers and students for the primary & secondary schools of Pimpri Chinchwad Municipal Corporation, Pune, Maharashtra which aims at educating students and teachers, by simplifying the environmental concepts given in their school syllabus. It sensitizes towards the conservation of nature and natural resources." />
                                    </ListItem>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>

                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: { xs: '12px', md: '16px' },
                        marginTop: '2rem',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Eco Tourism
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184", }}>
                                <List sx={{ textAlign: 'center' }}>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="Scitech Park is actively engaged in promoting environmental sensitive, responsible tourism with   low impact on the environment as well as community. Some of the activities it has initiated in the recent years include:" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="1. Dry land – Wetland Tourism Centre proposed at Ujani, Pune, Maharashtra, India covering the habitats of Great Indian Bustard, Chinkara, Blackbuck, Flamingos and many migratory bird species" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="2. Environment Education and Responsive Tourism Plan for the Bhimashankar Wildlife Sanctuary, Pune, Maharashtra, India." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="3. Ecological restoration and Nature Education Plan for Pashan Lake, Pune and plan for Establishment of Palm Park at Pashan Lake, Pune, Maharashtra, India." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="4. Undertaken studies for Exploration of Ecotourism potential of Sawantwadi Region for DLF, India." />
                                    </ListItem>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>

                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: { xs: '12px', md: '16px' },
                        marginY: '2rem',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Fumigation Chamber Study
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184", }}>
                                <List sx={{ textAlign: 'center' }}>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="1. Designing, construction and standardization of fumigation chamber and simulating major pollutants (SOx, NOx and Fly Ash) within the chamber was one of the major research initiative successfully undertaken by the Scitech Park, Pune. The project is considered as one of largest open top fumigation chamber that enables enclosure of full grown tree species enabling assessment of impacts on the natural reproductive outputs of plant coupled with rigorous simulation set up, resulting in maintenance of constant levels SOx, NOx and Fly Ash concentrations." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="2. This designing, construction and simulation of pollutants has enabled scientists to assess and predict beforehand the impacts of coal based thermal power plants on major economic crops such as Mango, Cashewnut and Chikoo." />
                                    </ListItem>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>

            <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Typography variant="h4" fontWeight="600" gutterBottom textAlign={'center'}>
                    Certification and Policy
                </Typography>

                {[
                    {
                        title: "International Certification: Green Globe 21",
                        text: `As part of our International Certification Programme Scitech Park, Pune carries out research, documentation and benchmarking for Eco Spas and Retreats. Under Green Globe 21 certification programme Scitech Park, Pune has successfully completed certifying Shillim Eco Spa, a 2,500 acre ecologically oriented resort in the Western Ghats near Pune, Maharashtra.
                    `,
                        image: GreenGlobe,
                    },
                    {
                        title: "Eco Housing:",
                        text: `The United States and India have a long history of fruitful collaboration in the energy and environment sectors. Efficiency in the building sector is of special significance to the USAID-Govt. of India program. “Mainstreaming Eco housing” program takes a lead to bring to the forefront the green housing movement in India and support long term sustainability of environment friendly and energy efficient – housing.
Under the above programme Scitech Park, Pune has developed “Eco friendly Building Material Database” taking Pune, Maharashtra as a pilot.`,
                        image: EcoHousing,
                    },
                    {
                        title: "Eco Housing Certification Program",
                        text: `Scitech Park, Pune has developed for the first time Climate Specific Eco housing Criteria for the housing sector in India to ensure energy efficient houses. It is the first ever unique initiative taken up jointly by Pune Municipal Corporation and Science and Technology Park, University of Pune to ensure environmentally sustainable construction in the urban over-populated sprawls of the state. This will have an astounding effect in arresting the adverse environmental impact of staggering commercial growth.`,
                        image: EcoHousing,
                    },
                    {
                        title: "CGEF- POLICY",
                        text: `The Clean, Green Eco-Friendly theme is an initiative towards infusing sustainable development in the industrial scenario in the state of Maharashtra. The thought behind this initiative was to formulate an Implementable, Sustainable and Participatory plan called Clean Green Eco Friendly Policy for Maharashtra Industrial Development Corporation, Govt. of Maharashtra. This is the first ever; participatory, implementable and self-sustainable policy formulated by Scitech Park, Pune, wherein it recognizes key principles enunciated in the Environmental Protection Act 1986 and the National Environment Policy of 2006 and are endorsed in the policy document. In addition to that the theories which are projected internationally e.g. Precautionary Principle, Principle of Economic Efficiency, “Polluter-Pays” Principle, Principle of Resource (use) Efficiency are ratified and effectively used for successful implementation of the policy.
                    `,
                        image: CGRF,
                    },
                ].map((item, index) => (
                    <Box key={index} sx={{ py: 4, color: "#1C1F35" }}>
                        <Container>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}>
                                    <Typography variant="h5" fontWeight="600" gutterBottom >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={'1.1rem'}
                                        paragraph
                                        sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                                    >
                                        {item.text}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            src={item.image}
                                            alt="EIR"
                                            style={{
                                                width: '50%',
                                                maxWidth: '400px',
                                                borderRadius: '5%',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                ))}
            </Box>

            {/* <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Typography variant="h4" fontWeight="600" gutterBottom textAlign={'center'}>
                    Eco Housing Certification Program
                </Typography>

                {[
                    {
                        title: "CGEF- POLICY",
                        text: `The Clean, Green Eco-Friendly theme is an initiative towards infusing sustainable development in the industrial scenario in the state of Maharashtra. The thought behind this initiative was to formulate an Implementable, Sustainable and Participatory plan called Clean Green Eco Friendly Policy for Maharashtra Industrial Development Corporation, Govt. of Maharashtra. This is the first ever; participatory, implementable and self-sustainable policy formulated by Scitech Park, Pune, wherein it recognizes key principles enunciated in the Environmental Protection Act 1986 and the National Environment Policy of 2006 and are endorsed in the policy document. In addition to that the theories which are projected internationally e.g. Precautionary Principle, Principle of Economic Efficiency, “Polluter-Pays” Principle, Principle of Resource (use) Efficiency are ratified and effectively used for successful implementation of the policy.
                    `,
                        image: CGRF,
                    },
                ].map((item, index) => (
                    <Box key={index} sx={{ py: 4, color: "#1C1F35" }}>
                        <Container>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}>
                                    <Typography variant="h5" fontWeight="600" gutterBottom >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={'1.1rem'}
                                        paragraph
                                        sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                                    >
                                        {item.text}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            src={item.image}
                                            alt="EIR"
                                            style={{
                                                width: '50%',
                                                maxWidth: '400px',
                                                borderRadius: '5%',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                ))}
            </Box> */}
        </>
    )
}

export default Environment;