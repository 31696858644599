import React from 'react'
import Banner from '../../Components/Banner'
import { Box, Button, Grid } from '@mui/material';


const IntegratedDev = () => {
    return (
        <>
            <Banner title={"Integrated Village Development"}
                fontSize={{xs: "2rem", md: "3rem", lg: "4rem" }}
            />

            <Box
                py={{xs:'2rem', md:'4rem'}}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Integrated-village-development.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                pb={{xs:'2rem', md:'4rem'}}
            >
                <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#1c2b78",
                            color: "white",
                            padding: "1rem 4rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": { backgroundColor: "#1e3192" },
                        }}
                        onClick={() => {
                            window.open(`/integrated-village-development/panawadi`, "_self");
                        }}
                    >
                        Panawadi
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#1c2b78",
                            color: "white",
                            padding: "1rem 4rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": { backgroundColor: "#1e3192" },
                        }}
                        onClick={() => {
                            window.open(`/integrated-village-development/chandar`, "_self");
                        }}
                    >
                        Chandar
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default IntegratedDev;
