import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const BhisewadiPlantationData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/6.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/7.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/8.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/9.webp`
    },
]

const BhisewadiPlantation = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Panawadi Plantation (Science and Technology Park, Pune and FICCI FLO, Pune Chapter Initiative) conducted on 9th Dec. 2020 at Bhisewadi, Panawadi Village, Pune District.
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/BhisewadiPlantation/COVER-IMAGE.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Science and Technology Park, Pune has adopted Panawadi village for its complete socio-economic and ecological development and to transform it into a model replicable village with highest United Nations Sustainable Development Goals. Science and Technology Park has taken a mission of planting 50,000 fruit trees within three years as an effort to enhance ecology as well as economy and nutrition status of the Panawadi population. Under this initiative Scitech Park has completed plantation of 1000 fruit trees of 34 different varieties on 09th December, 2020 at Bhisewadi, within Panawadi village, Dist. Pune. Some of the important fruit trees those were planted are varieties of Coconuts, Guavas, Mangoes, Custard Apple, Tamarind, Black Plum, Fig, Jack Fruit, Sapota, Lemon, Indian Gooseberry, Pomegranate etc. and flowering plants like Magnolia Champaca, Golden Shower, Spathodia etc. Ecologically significant plants which are important for birds like Banyan, Ficus Religiosa, varieties of Bamboo, Singapore Cherry etc. were also planted.
                    <br />
                    <br />
                    The project has been supported by FICCI FLO, Pune Chapter. The dignitaries present for the plantation programme were Dr. Rajendra Jagdale, DG & CEO, Science and Technology Park, Pune; Ms. Aneeta Sanas, Chairperson, FICCI FLO, Pune Chapter; Ms. Usha Poonawalla, Sr. Vice Chairperson, FICCI FLO, Pune Chapter; Ms. Mariam, Member, FICCI FLO, Pune Chapter; Ms. Poonam Buch, Member, FICCI FLO, Pune Chapter; Ms. Naina Kanal, Member, FICCI FLO, Pune Chapter; Ms. Gayatri Tewari, Member, FICCI FLO, Pune Chapter; Mr. Badri Poonawalla, Pune; Mr. Ravi Kapoor, Rotary Club, Pune; Mr. Gurvinder Singh, Guru Wonder, Pune; Mr. Vigyan Arya, Jagran Communication, Delhi; Mr. Rahul Shinde, URMEE, Pune; Mr. Subhash M. H., URMEE, Pune; Mrs. Nalini Lole, President, Panchayat Samiti, Panawadi; Mrs. Anita Lole, Sarpanch, Panawadi; Mr. Haribhau Lole; Mr. Sandip Bhise; Mrs. Sushma Bhise; Mr. Anil Magar; Mr. Namdev Magar and members of Adarsh Mahila Bachat Gat and Waghjai Mata Shetmal Utpadak Company, Panawadi.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {BhisewadiPlantationData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default BhisewadiPlantation;
