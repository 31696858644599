import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import ChandarCard from '../ChandarCard';

const SolarWaterSystemData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/2.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/3.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/4.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/5.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/6.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/7.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/8.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/9.webp`
    },
]

const SolarWaterSystem = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>

            <Box sx={{ pt: 4, textAlign: 'justify' }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" textAlign={'center'} gutterBottom>
                        Inauguration of 25 Hp Solar Water Pumping System at Panawadi Village, Taluka Purandar, Dist. Pune on 03rd March, 2021
                    </Typography>
                </Container>
            </Box>

            <Box
                py={{ xs: '2rem', md: '2rem' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/panwadi/PanwadiSubpages/SolarWaterSystem/cover.webp`}
                    style={{
                        width: '80%',
                    }}
                    alt="" />
            </Box>

            <Container sx={{ textAlign: 'justify' }}>
                <Typography variant="h6" pb={'2rem'}>
                    Science and Technology Park, Pune has adopted Panawadi village for its total socio-economic and ecological development and convert this into a model replicable sustainable village with highest United Nations Sustainable Development Goals. Scitech Park is minutely planning and implementing projects to ensure the holistic development of the Panawadi village ecosystem.
                    <br />
                    <br />
                    With an aim to solve the most crucial irrigation and drinking water problem of Panawadi village, Scitech Park along with Finolex Pipes, Pune and their CSR partner, Mukul Madhav Foundation, Pune completed installation, commissioning and testing of 25Hp solar water pumping system at Panawadi along with laying out of 2200 mtrs of pipeline from Chimta Dam to Patzai Dam in order to lift the water from lower junction to upper main dams saving huge amount of energy ensuring much less or NIL electricity bill to the villagers and continuous availability of water throughout the year. All the pipes had been manufactured and supplied by Finolex Plasson Industries Pvt. Ltd. The laying of the pipeline admeasuring 3200 metres from lower Chimta Dam to upper Maldhara Dam had been completed by the Waghzai Mata Shetmal Utpadak Company, Panawadi.
                    <br />
                    <br />
                    The inauguration programme of Solar Water Pumping System was conducted on Wednesday, 03rd March, 2021 at Chimta Dam, Panawadi Village, Tq. Purandar, Dist. Pune. The dignitaries present for the inauguration programme were Mr. Saurabh Dhanorkar, Director, Finolex Industries Ltd., Pune; Mr. Babalu Mokale, Executive CSR, Mukul Madhav Foundation, Pune, Mrs. Nalini Lole, President, Panchayat Samiti, Tq. Purandar; Mr. Abasaheb Lole, Sarpanch, Panawadi; Mr. Mauli Bhise, Dy. Sarpanch, Panawadi; Mr. Haribhau Lole; Panawadi; Mr. Sandip Bhise, Panawadi; Mrs. Sushma Bhise, Panawadi; Mr. Vaibhav Nimgire, Science and Technology Park, Pune; Mr. Sanmati Shetgar, Science and Technology Park, Pune and Members of Waghzai Mata Shetmal Utpadak Company, Panawadi.
                    <br />
                    <br />
                    This water recycling project supported by Finolex Pipes, Pune with their CSR partner Mukul Madhav Foundation, Pune will greatly encourage the farmers of Panawadi to cultivate their agricultural lands with crops other than paddy like vegetables, flowering and fruit trees and get good yield ensuring a good income instead of migrating to cities and nearby big towns in search of a decent livelihood.
                </Typography>
            </Container>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {SolarWaterSystemData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default SolarWaterSystem;
