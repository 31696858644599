import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container } from '@mui/material';


const ScitechAirosan = () => {
    return (
        <>
            <Banner title={"Scitech AIROSAN"} />
            <Box sx={{ py: { xs: 2, md: 6 }, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            src={`${process.env.REACT_APP_STP_URL}/productInnovationStaticData/Scitech-Airosan-Brochure.jpg`}
                            alt='EIR'
                            style={{
                                width: '100%',
                            }}
                            sx={{
                                width: {
                                    xs: '88%',
                                    md: '75%',
                                },
                            }}
                             />
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ScitechAirosan