import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const BlogBanner = ({ count }) => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: { xs: "60vh", md: '35vh', lg: '50vh' },
                backgroundImage: `url(https://media.istockphoto.com/id/1392316992/photo/stream-at-a-concert-in-a-hall-without-spectators.jpg?s=612x612&w=0&k=20&c=T4k4sd-pf5m5dPFvc47ydcjvIf5xu8OIcwE-tlsXZFc=)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem" }}
                    fontWeight="600"
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                >
                    News and Media
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    {/* <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 1.5,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                News and Media count
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                {count}
                            </Typography>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={10} md={2.8}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Read Every Month
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                1000+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Editors
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                30+
                            </Typography>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
};

export default BlogBanner;
